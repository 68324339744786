import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  createReportUser(reportId, userId) {
    return axiosIns.post(
      '/v2/eod_report/report_users',
      snakecaseKeys({ reportId, userId }),
    )
  },
  destroyReportUser(id) {
    return axiosIns.delete(`/v2/eod_report/report_users/${id}`)
  },
}
