<template>
  <v-data-table
    :loading="loading"
    :headers="aggregationTable.headers"
    :items="aggregationTable.items"
    :header-props="{ sortByText: 'ソート' }"
    :footer-props="{ itemsPerPageText: '行/ページ:' }"
    no-data-text="データがありません"
    loading-text="読込中..."
    hide-default-footer
    :items-per-page="-1"
    class="aggregation-table"
    :class="{ 'fixed-first-column': isUserAggregation }"
  >
    <template #top>
      <v-toolbar
        flat
        color="transparent"
      >
        <slot
          name="toolbar-title"
          :aggregation="aggregation"
        />

        <v-spacer />
        <slot name="toolbar-filters" />
        <v-btn
          icon
          depressed
          :ripple="false"
          :loading="loading"
          :disabled="loading"
          @click.prevent="$emit('reload', aggregationTable.id)"
        >
          <v-icon>
            {{ icons.mdiReload }}
          </v-icon>
        </v-btn>

        <template v-if="$slots['toolbar-menu']">
          <v-divider
            vertical
            inset
            class="mx-2"
          />
          <slot name="toolbar-menu" />
        </template>
      </v-toolbar>
    </template>

    <!-- eslint-disable-next-line vue/no-useless-template-attributes -->
    <template
      v-for="header in aggregationTable.businessRuleHeaders"
      #[`item.${header.value}`]="{ item }"
    >
      <span :key="`business-rule-result-input-for-current-club-business-rule-id-${header.value}`">
        <v-checkbox
          v-if="isBooleanItem(item, header.value)"
          :input-value="item[header.value]"
          :disabled="loading"
          readonly
          dense
          hide-details
        />
        <v-text-field
          v-else-if="isNumberItem(item, header.value)"
          :value="numberText(item, header.value)"
          :disabled="loading"
          readonly
          dense
          hide-details
        />
        <v-text-field
          v-else
          :value="item[header.value]"
          :disabled="loading"
          :type="inputType(item, header.value)"
          readonly
          dense
          hide-details
        />
      </span>
    </template>
  </v-data-table>
</template>

<script>
import { mdiReload } from '@mdi/js'
import formatNumberOnBlur from '@/directives/formatNumberOnBlur'
import useAggregationTable from '@/views/v2-temp/eod-closing/composables/useAggregationTable'

export default {
  directives: {
    formatNumberOnBlur,
  },
  props: {
    aggregation: {
      type: Object,
      default: () => {
        return {
          attributes: {
            id: null,
            name: '',
            description: '',
            businessRules: { data: [] },
            results: { data: [] },
            contexts: { data: [{ attributes: { results: { data: [] } } }] },
          },
        }
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const {
      aggregationTable,
      isUserAggregation,
      isBooleanItem,
      isNumberItem,
      isNew,
      isNotEditable,
      inputType,
      numberText,
      saveResultValue,
      onMenuInput,
    } = useAggregationTable(props, emit)

    return {
      isUserAggregation,
      isBooleanItem,
      isNumberItem,
      numberText,
      isNotEditable,
      inputType,
      isNew,
      aggregationTable,
      saveResultValue,
      onMenuInput,
      icons: {
        mdiReload,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

@include theme--child(aggregation-table) using ($material) {
  th.fixed-cell,
  td.fixed-cell {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 3;
    background: map-deep-get($material, 'app-bar');
  }

  th.fixed-cell {
    z-index: 4;
  }

  .v-progress-linear {
    z-index: 5;
  }
}

.aggregation-table {
  white-space: nowrap;
}
</style>
