import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getUserReports() {
    return axiosIns.get(
      '/v2/eod_report/user_reports',
    )
  },
  getUserReport(id, date, userId = null, displayMode = null) {
    return axiosIns.get(
      `/v2/eod_report/user_reports/${id}`,
      { params: snakecaseKeys({ date, userId, displayMode }) },
    )
  },
  getAvailableUsers(id) {
    return axiosIns.get(`/v2/eod_report/user_reports/${id}/available_users`)
  },
}
