<!--NOTE: 時間ないのでコンポーネントに状態もたせてとりあえずつくっちゃうよ(anti pattern)-->
<template>
  <v-container :key="`eod-closing-${date}`">
    <h1>{{ date }} クロージング</h1>

    <v-menu
      transition="scale-transition"
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          :prepend-icon="icons.mdiCalendar"
          :value="date"
          style="width: fit-content;"
          v-bind="attrs"
          v-on="on"
        >
          <template #append-outer>
            <v-btn
              color="secondary"
              :ripple="false"
              small
              @click="getToday"
            >
              本日
            </v-btn>
          </template>
        </v-text-field>
      </template>

      <v-date-picker
        v-model="date"
        locale="ja"
        :day-format="date => new Date(date).getDate()"
        color="primary"
        :max="today"
        no-title
      />
    </v-menu>

    <v-stepper
      v-model="step"
      flat
      style="background: none"
    >
      <v-stepper-header>
        <template v-for="(stepper, stepIdx) in steppers">
          <v-stepper-step
            :key="`step-${stepIdx}`"
            :complete="step > stepIdx"
            editable
            :step="stepIdx"
          >
            {{ stepper }}
          </v-stepper-step>
          <v-divider
            v-if="stepIdx < steppers.length - 1"
            :key="`step-divider-${stepIdx}`"
          />
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="0">
          <v-row>
            <v-col><h2>データセット管理</h2></v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <TimecardsTable :date="date" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <Data :show-date-picker="false" />
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col>
              <v-tabs v-model="tab">
                <v-tab>勤怠</v-tab>
                <v-tab>伝票</v-tab>
                <v-tab>販売履歴</v-tab>
              </v-tabs>

              <v-tabs-items
                v-model="tab"
                touchless
              >
                <v-tab-item>
                  <TimecardsTable :date="date" />
                </v-tab-item>
                <v-tab-item>
                  <TableDetailsTable :date="date" />
                </v-tab-item>
                <v-tab-item>
                  <VendingHistoryDetailsTable :date="date" />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row> -->
        </v-stepper-content>

        <v-stepper-content step="1">
          <ExpensesTable :date="date" />
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
            >
              <v-spacer />
              <v-btn
                color="primary"
                :ripple="false"
                :disabled="!isAnyJobMissing || isAnyJobInProgress"
                :loading="isAnyJobInProgress"
                @click="enqueue = true"
              >
                <v-icon left>
                  {{ icons.mdiSync }}
                </v-icon>
                日計ジョブ実行
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <Aggregations
                :date="date"
                :enqueue="enqueue"
                @enqueued="enqueue = false"
                @is-any-job-in-progress="isAnyJobInProgress = $event"
                @is-all-job-completed="isAllJobCompleted = $event"
                @is-any-job-missing="isAnyJobMissing = $event"
              />
            </v-col>
          </v-row>
        </v-stepper-content>

        <!-- <v-stepper-content step="3">
          TODO: レジ締め
          流用!! -> tx
        </v-stepper-content> -->

        <v-stepper-content step="3">
          <v-row>
            <v-col cols="12">
              <v-btn
                x-large
                block
                :ripple="false"
                color="primary"
                :to="{ name: 'eod-report', query: { date } }"
              >
                完了
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import {
  ref,
  inject,
  onMounted,
  watch,
} from '@vue/composition-api'
import { mdiSync, mdiCalendar } from '@mdi/js'
import { useRouter } from '@core/utils'
import { parseISO, isAfter } from 'date-fns'
import TimeApi from '@/api/v2/utility/Time'
import Aggregations from './components/Aggregations.vue'
import Data from '@/views/waiter/Data.vue'
import TimecardsTable from './components/TimecardsTable.vue'
import ExpensesTable from './components/ExpensesTable.vue'
// import CashRegister from '@/views/components/daily_report/Closing/CashRegister.vue'

export default {
  components: {
    TimecardsTable,
    ExpensesTable,
    Data,
    // CashRegister,
    Aggregations,
  },
  setup() {
    const currentClubMeta = inject('currentClubMeta', { today: null })
    const { route, router } = useRouter()
    const today = currentClubMeta.today || new Date().toISOString().slice(0, 10)
    const date = ref(route.value.query.date || today)
    const step = ref(0)
    const tab = ref(0)
    const fixedAt = ref('')
    const isAnyJobInProgress = ref(false)
    const isAllJobCompleted = ref(false)
    const isAnyJobMissing = ref(true)
    const enqueue = ref(false)
    const steppers = [
      'データセット管理',
      '経費管理',
      '日計',
      // 'レジ締め',
      '完了',
    ]

    const getToday = async () => {
      const res = await TimeApi.getToday()
      if (res.data.status === 'success') {
        date.value = res.data.data
      }
    }
    const getEndTimeOfDay = async () => {
      const res = await TimeApi.getEndTimeOfDay(date.value)
      if (res.data.status === 'success') {
        fixedAt.value = res.data.data
      }
    }

    watch(() => date.value, newDate => {
      const dateObj = parseISO(newDate)
      const todayObj = parseISO(today)
      if (isAfter(dateObj, todayObj)) date.value = today

      router.replace({
        name: route.value.name,
        query: {
          ...route.value.query,
          date: date.value,
        },
      })
    }, { immediate: true })

    onMounted(async () => {
      if (!date.value) await getToday()
      getEndTimeOfDay()
    })

    return {
      step,
      tab,
      date,
      today,
      fixedAt,
      steppers,
      isAnyJobInProgress,
      isAllJobCompleted,
      isAnyJobMissing,
      enqueue,

      getToday,

      icons: {
        mdiSync,
        mdiCalendar,
      },
    }
  },
}
</script>

<style lang="scss">
.aggregation-table {
  white-space: nowrap;
}
</style>
