import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getClubReports() {
    return axiosIns.get(
      '/v2/eod_report/club_reports',
    )
  },
  getClubReport(id, date, displayMode = 'monthly_daily') {
    return axiosIns.get(
      `/v2/eod_report/club_reports/${id}`,
      { params: snakecaseKeys({ date, displayMode }) },
    )
  },
}
