/* eslint-disable no-param-reassign */
import { toRefs, ref, computed } from '@vue/composition-api'
import { judgeType, interpretValue } from '@/utils/typeUtils'

export default (props, emit) => {
  const { aggregation, loading } = toRefs(props)

  const justSaved = ref(false)

  const isUserAggregation = computed(() => /(UserReport|UserAggregation)$/.test(aggregation.value.attributes.type))
  const isBooleanItem = computed(() => (item, businessRuleId) => item.meta.inputTypes[businessRuleId] === 'boolean')
  const isNumberItem = computed(() => (item, businessRuleId) => item.meta.inputTypes[businessRuleId] === 'number')
  const isNew = computed(() => (item, businessRuleId) => !item.meta.businessRuleResultIds[businessRuleId])
  const isNotEditable = computed(() => (item, businessRuleId) => loading.value || !item.meta.editable[businessRuleId])
  const inputType = computed(() => (item, businessRuleId) => item.meta.inputTypes[businessRuleId])
  const numberText = computed(() => (item, businessRuleId) => item[businessRuleId].toLocaleString('ja-JP'))

  /**
   * テーブルのヘッダーを構築する関数
   *
   * type BusinessRuleId = number
   *
   * interface BusinessRuleData {
   *   id: string;
   *   type: string;
   *   attributes: {
   *     id: BusinessRuleId;
   *     name: string;
   *     description: string | null;
   *     dependentBusinessRuleIds?: BusinessRuleId[]
   *   };
   * };
   *
   * interface Header { text: string; value: string; }
   * @param {Object} BusinessRuleData[]
   * @returns {Object} Header[]
   */
  const buildTableHeaders = businessRulesData => {
    return businessRulesData.map(data => {
      return {
        text: data.attributes.name,
        value: data.id,
        // autoにしてもcalculate-widthsを指定してもコンテンツに応じて幅がセットされないので一旦キメで
        maxWidth: '160px',
        minWidth: '160px',
        width: '160px',
      }
    })
  }

  /**
   * resultsData: ある1つの Context(行) に紐づく Result[] の配列
   *   例: [
   *     {
   *       attributes: {
   *         businessRuleId: 1,
   *         businessRuleResultId: null,
   *         value: [
   *           { date: '2024-01-01', value: 100, businessRuleResultId: 101 },
   *           { date: '2024-01-02', value: 200, businessRuleResultId: 102 },
   *         ],
   *         ...
   *       }
   *     },
   *     {
   *       attributes: {
   *         businessRuleId: 2,
   *         value: [
   *           { date: '2024-01-01', value: 10, businessRuleResultId: 201 },
   *           ...
   *         ],
   *       }
   *     }
   *   ]
   *
   * 【今回の狙い】
   *   "value" に複数日付が入っている場合でも、
   *   同じ日付であれば1行にまとめる （1行 = 1日）
   */
  function buildTableItems(resultsData, contextId, contextType, contextName) {
    // 日付ごとのアイテム(行)をまとめるためのマップ
    const itemsByDate = {}

    resultsData?.forEach(result => {
      const {
        businessRuleId,
        businessRuleResultId,
        value, // ここが [{ date, value, businessRuleResultId }, ...]
        dependentResources,
        dependentBusinessRuleIds,
        editable,
      } = result.attributes

      // "value" の中身を日ごとにまとめる
      if (Array.isArray(value)) {
        value.forEach(daily => {
          const { date } = daily

          // interpretValue は既存コードにあった値の変換関数
          const interpretedValue = interpretValue(daily.value)

          // すでにこの date のアイテムが無い場合は初期化しておく
          if (!itemsByDate[date]) {
            itemsByDate[date] = {
              date,
              id: contextId,
              contextType,
              name: contextName,
              meta: {
                businessRuleResultIds: {},
                dependentResources: {},
                dependentBusinessRuleIds: {},
                editable: {},
                _originalValues: {},
                _inputTypes: {},
                // 既存コードのまま: getter で originalValues と inputTypes を参照
                get originalValues() {
                  return { ...this._originalValues }
                },
                get inputTypes() {
                  return { ...this._inputTypes }
                },
              },
            }
          }

          // itemsByDate[date] に対して、今の businessRuleId をキーとして値を代入
          itemsByDate[date][businessRuleId] = interpretedValue

          // メタ情報 (businessRuleResultIds, editable など) も同様にまとめる
          itemsByDate[date].meta.businessRuleResultIds[businessRuleId] = daily.businessRuleResultId || businessRuleResultId
          itemsByDate[date].meta.editable[businessRuleId] = editable
          itemsByDate[date].meta._originalValues[businessRuleId] = interpretedValue
          itemsByDate[date].meta._inputTypes[businessRuleId] = judgeType(daily.value)

          itemsByDate[date].meta.dependentResources[businessRuleId] = dependentResources
          itemsByDate[date].meta.dependentBusinessRuleIds[businessRuleId] = dependentBusinessRuleIds
        })
      }
    })

    // itemsByDate は「日付: オブジェクト」の形なので、最後に values を取り出して配列にする
    return Object.values(itemsByDate)
  }

  const aggregationTable = computed(() => {
    const {
      id,
      name,
      description,
      businessRules,
      contexts,
      type,
    } = aggregation.value.attributes

    const businessRuleHeaders = buildTableHeaders(businessRules.data)
    const context = contexts.data[0]
    const {
      contextType,
      id: contextId,
      name: contextName,
      results,
    } = context?.attributes || {}

    const headers = [...businessRuleHeaders]
    headers.unshift({
      text: '日付',
      value: 'date',
      maxWidth: '160px',
      minWidth: '160px',
      width: '160px',
      class: 'fixed-cell',
      cellClass: 'fixed-cell',
    })

    return {
      id,
      name,
      description,
      businessRuleHeaders,
      headers,
      items: buildTableItems(results?.data, contextId, contextType, contextName),
      type,
    }
  })

  const saveResultValue = (item, businessRuleId, resultValue, menu) => {
    justSaved.value = true
    menu.value = false
    const { id: contextId, contextType, date } = item

    emit('save', {
      aggregationId: aggregation.value.attributes.id,
      contextId,
      contextType,
      businessRuleId,
      resultValue,
      businessDate: date,
    })
  }

  const onMenuInput = (open, item, businessRuleId) => {
    if (open) return

    // 保存時に閉じた時は何もしない
    if (justSaved.value) {
      justSaved.value = false

      return
    }

    // escや外側クリックなどで編集モードを抜ける場合は元に戻す
    item[businessRuleId] = item.meta.originalValues[businessRuleId]
  }

  return {
    aggregationTable,
    isUserAggregation,
    isBooleanItem,
    isNumberItem,
    isNew,
    isNotEditable,
    inputType,
    numberText,
    saveResultValue,
    onMenuInput,
  }
}
