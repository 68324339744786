import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getPayrollContainers() {
    return axiosIns.get('/v2/employee/payroll/payroll_aggregation_containers')
  },
  getPayrollContainer(id, { date, startDate, interval = 'monthly' }) {
    return axiosIns.get(
      `/v2/employee/payroll/payroll_aggregation_containers/${id}`,
      {
        params: snakecaseKeys({
          date,
          startDate,
          interval,
        }),
      },
    )
  },
}
