import axiosIns from '@/plugins/axios'

export default {
  getLabels() {
    return axiosIns.get(
      '/v2/eod_closing/expense_quaternary_labels',
    )
  },
  createLabel(name) {
    return axiosIns.post(
      '/v2/eod_closing/expense_quaternary_labels',
      { name },
    )
  },
  destroyLabel(id) {
    return axiosIns.delete(`/v2/eod_closing/expense_quaternary_labels/${id}`)
  },
}
