import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getAggregations(date) {
    return axiosIns.get(
      '/v2/eod_report/eod_report_aggregations',
      { params: snakecaseKeys({ date }) },
    )
  },
  enqueueAggregation(id, date, options = {}) {
    const { forceReload = false, forceRefresh = false } = options

    return axiosIns.get(
      `/v2/eod_report/eod_report_aggregations/${id}/enqueue`,
      { params: snakecaseKeys({ date, forceReload, forceRefresh }) },
    )
  },
  getAggregationJobStatus(id, date) {
    return axiosIns.get(
      `/v2/eod_report/eod_report_aggregations/${id}/status`,
      { params: { date } },
    )
  },
  getAvailableUsers(id) {
    return axiosIns.get(`/v2/eod_report/eod_report_aggregations/${id}/available_users`)
  },
}
