<template>
  <div class="payroll-container">
    <header class="payroll-header">
      <div class="payroll-header-left">
        {{ reportDate }} 報酬明細書
      </div>
      <div class="payroll-header-center">
        {{ currentClub.name }}
      </div>
      <div class="payroll-header-right">
        {{ user.attributes.name }} 様
      </div>
    </header>

    <v-row
      v-for="row in containerRows"
      :key="row.id"
      class="payroll-row"
    >
      <v-col
        v-for="col in row.attributes.cols.data"
        :key="col.id"
        class="payroll-col"
      >
        <div
          v-for="aggregation in col.attributes.aggregations.data"
          :key="aggregation.id"
          class="payroll-aggregation"
        >
          <h5>{{ aggregation.attributes.name }}</h5>

          <table class="payroll-aggregation-table">
            <colgroup>
              <col
                v-for="br in aggregation.attributes.businessRules.data"
                :key="br.id"
              />
            </colgroup>
            <thead>
              <tr>
                <th
                  v-for="br in aggregation.attributes.businessRules.data"
                  :key="br.id"
                >
                  {{ br.attributes.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="ctx in aggregation.attributes.contexts.data"
                :key="ctx.id"
              >
                <td
                  v-for="br in aggregation.attributes.businessRules.data"
                  :key="br.id"
                >
                  <!-- <v-text-field
                    :value="findResultValue(ctx, br)"
                    dense
                    hide-details
                  >
                  </v-text-field> -->
                  {{ findResultValue(ctx, br) || '\u00a0' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { parseISO, format } from 'date-fns'
import useCurrentData from '@/views/composable/useCurrentData'

export default {
  props: {
    container: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    startDate: {
      type: String,
      default: () => new Date().toISOString().slice(0, 10),
    },
  },
  setup(props) {
    const { currentClub } = useCurrentData()

    const containerRows = computed(() => {
      if (!props.container.attributes.rows) return []

      return props.container.attributes.rows.data || []
    })

    const reportDate = computed(() => {
      return format(parseISO(props.startDate), 'yyyy年MM月')
    })

    const findResultValue = (ctx, br) => {
      const results = ctx.attributes.results.data
      const found = results.find(r => {
        return r.attributes.businessRuleId === br.attributes.id
      })

      return found ? found.attributes.value : ''
    }

    return {
      currentClub,
      containerRows,
      findResultValue,
      reportDate,
    }
  },
}
</script>

<style scoped>
.payroll-container {
  border: 1px solid #ccc;
  padding: 1rem;
  margin-bottom: 2rem;
  font-size: 12px
}

.payroll-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.8rem 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #ccc;
}

.payroll-header-left,
.payroll-header-center,
.payroll-header-right {
  margin: 0 1rem;
  font-weight: bold;
  font-size: 14px;
}

.payroll-header-left {
  text-align: left;
  flex: 1;  /* 幅を可変にする */
}
.payroll-header-center {
  text-align: center;
  flex: 1;
}
.payroll-header-right {
  text-align: right;
  flex: 1;
}

.payroll-aggregation {
  padding: 0.5rem;
}
.payroll-aggregation-table {
  border-collapse: collapse;
  width: 100%;
  /* table-layout: fixed; */
}
.payroll-aggregation-table th,
.payroll-aggregation-table td {
  border: 1px solid #999;
  padding: 0.3rem 0.6rem;
  text-align: center;
}
.payroll-aggregation-table col {
  width: 1%; /* 均等に分割するためのトリック */
}
@media print {
  .payroll-container {
    page-break-inside: avoid;
    color: black !important;
  }
  .payroll-container,
  .payroll-header,
  .payroll-aggregation-table th,
  .payroll-aggregation-table td {
    border-color: black !important;
  }
}
</style>
