<template>
  <v-select
    v-if="clubReports.length > 1"
    v-model="selectedClubReportIdModel"
    :items="clubReports"
    item-value="attributes.id"
    item-text="attributes.name"
    hide-details
    class="report-selection"
  />
  <v-toolbar-title v-else>
    {{ defaultTitle }}
  </v-toolbar-title>
</template>

<script>
import { useVModel } from '@vueuse/core'

export default {
  props: {
    clubReports: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectedClubReportId: {
      type: [String, Number],
      required: true,
    },
    defaultTitle: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const selectedClubReportIdModel = useVModel(props, 'selectedClubReportId', emit)

    return {
      selectedClubReportIdModel,
    }
  },
}
</script>

<style lang="scss">
.report-selection {
  width: min-content;
  .v-select__selections {
    font-size: 1.25rem !important;
    line-height: 1.5 !important;
  }
}
</style>
