<template>
  <v-row>
    <v-spacer />
    <v-col>
      <v-select
        :value="displayModeModel"
        :items="displayModes"
        hide-details
        @change="changeDisplay({ displayMode: $event, user: { value: userIdModel } })"
      />
    </v-col>
    <v-col v-if="shouldSelectUser">
      <v-combobox
        :value="selectedUserName"
        :error="!userIdModel"
        placeholder="ユーザー"
        :items="selectableUsers"
        hide-details
        @change="changeDisplay({ displayMode: displayModeModel, user: $event })"
      />
    </v-col>
  </v-row>
</template>

<script>
import {
  computed,
} from '@vue/composition-api'
import { useVModel } from '@vueuse/core'

export default {
  props: {
    displayMode: {
      type: String,
      required: true,
      default: 'monthly_daily',
    },
    userId: {
      required: true,
      default: null,
      validator: val => (val === null || typeof val === 'number'),
    },
    userReport: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        type: 'userReport',
        attributes: {
          id: null,
          name: null,
          description: null,
          position: null,
          type: 'userReport',
          businessRules: {
            data: [
              {
                id: null,
                type: 'businessRule',
                attributes: {
                  id: null,
                  type: null,
                  name: null,
                  position: null,
                },
              },
            ],
          },
          reportUsers: {
            data: [
              {
                id: null,
                type: 'reportUser',
                attributes: {
                  id: null,
                  reportId: null,
                  userId: null,
                  user: {
                    data: {
                      id: null,
                      type: 'user',
                      attributes: {
                        id: null,
                        name: null,
                        role: null,
                      },
                    },
                  },
                },
              },
            ],
          },
        },
      }),
    },
  },
  setup(props, { emit }) {
    const displayModeModel = useVModel(props, 'displayMode', emit)
    const userIdModel = useVModel(props, 'userId', emit)

    const shouldSelectUser = computed(() => {
      return displayModeModel.value !== 'monthly_all_users_sum'
    })

    const isPending = computed(() => {
      return shouldSelectUser.value && !userIdModel.value
    })

    const selectableUsers = computed(() => {
      return props.userReport.attributes?.reportUsers?.data?.map(reportUser => {
        const { name, id } = reportUser.attributes.user.data.attributes

        return { text: name, value: id }
      }) || []
    })

    const selectedUserName = computed(() => {
      if (!userIdModel.value) return ''

      return selectableUsers.value.find(user => user.value === userIdModel.value)?.text
    })

    const changeDisplay = ({ displayMode, user }) => {
      displayModeModel.value = displayMode

      if (shouldSelectUser.value) {
        if (user) {
          userIdModel.value = user.value
        } else {
          userIdModel.value = null

          return
        }
      } else {
        userIdModel.value = null
      }

      emit('change-display', {
        displayMode: displayModeModel.value,
        userId: userIdModel.value,
      })
    }

    return {
      displayModeModel,
      userIdModel,
      shouldSelectUser,
      isPending,
      selectableUsers,
      selectedUserName,
      changeDisplay,
      displayModes: [
        {
          text: '全ユーザー/月別合計',
          value: 'monthly_all_users_sum',
        },
        {
          text: 'ユーザー別/月別日次',
          value: 'monthly_daily',
        },
        {
          text: 'ユーザー別/月別合計',
          value: 'monthly_sum',
        },
      ],
    }
  },
}
</script>
