import { ref, watch, onMounted } from '@vue/composition-api'

const LS_KEY = 'myAppUserPreferences'

export default () => {
  const preferences = ref({
    'eod_closing:expenses': {
      showTertiaryColumns: false,
    },
  })

  onMounted(() => {
    const stored = localStorage.getItem(LS_KEY)
    if (stored) {
      try {
        const parsed = JSON.parse(stored)

        preferences.value = { ...preferences.value, ...parsed }
      } catch (e) {
        // JSONパース失敗などは無視
      }
    }
  })

  watch(
    preferences,
    newVal => {
      localStorage.setItem(LS_KEY, JSON.stringify(newVal))
    },
    { deep: true },
  )

  return { preferences }
}
