<template>
  <div v-if="isLoading">
    <v-skeleton-loader
      type="table-heading"
      class="mb-8"
    />
    <v-row>
      <v-col
        v-for="loadingIdx of 36"
        :key="`loading${loadingIdx}`"
        md="3"
        sm="3"
        cols="4"
        class="my-2 d-flex align-center justify-center"
      >
        <v-skeleton-loader
          type="avatar"
          size="60"
        />
      </v-col>
    </v-row>
  </div>

  <div
    v-else
  >
    <div class="pa-3 w-full d-flex align-center justify-end">
      <v-dialog
        width="500"
        persistent
      >
        <template #activator="{ on: newUserOn, attrs: newUserAttrs }">
          <v-btn
            fab
            small
            color="primary"
            :ripple="false"
            v-bind="newUserAttrs"
            v-on="newUserOn"
          >
            <v-icon v-text="icons.mdiPlus" />
          </v-btn>
        </template>

        <template #default="newUserDialog">
          <UserForm
            :role="currentRole"
            :user-system-flags="userSystemFlags"
            @submit="createUser($event); newUserDialog.value = false"
            @cancel="newUserDialog.value = false"
          />
        </template>
      </v-dialog>
    </div>

    <v-tabs
      v-if="users.length > 1"
      v-model="currentTabIdx"
      show-arrows
      background-color="transparent"
      class="mb-6 elevation-0"
    >
      <v-tab
        v-for="role in roles"
        :key="`v-tab-${role[0]}`"
      >
        {{ role[1] }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="currentTabIdx"
      style="background: transparent"
      touchless
    >
      <v-tab-item
        v-for="role in roles"
        :key="`v-tab-item-${role[0]}`"
      >
        <v-row class="pb-5">
          <v-col
            v-for="user of usersByRole[role[0]]"
            :key="`user${user.id}`"
            md="3"
            sm="3"
            cols="4"
            class="my-2"
          >
            <div class="d-flex flex-column align-center mb-4">
              <v-dialog
                width="500"
                persistent
              >
                <template #activator="{ on: persistedUserOn, attrs: persistedUserAttrs }">
                  <v-hover v-slot="{ hover }">
                    <v-avatar
                      size="60"
                      class="cursor-pointer"
                      :class="{ 'elevation-4' : hover }"
                      color="secondary"
                      v-bind="persistedUserAttrs"
                      v-on="persistedUserOn"
                    >
                      <v-img
                        v-if="user.attributes.profileImage"
                        :src="user.attributes.profileImage"
                      />
                      <span
                        v-else
                        class="text-xs font-weight-bold"
                      >
                        {{ user.attributes.name.substring(0, 3) }}
                      </span>
                    </v-avatar>
                  </v-hover>
                </template>
                <template #default="editUserDialog">
                  <UserForm
                    :user="user"
                    :role="currentRole"
                    :user-system-flags="userSystemFlags"
                    @submit="updateUser($event); editUserDialog.value = false"
                    @cancel="editUserDialog.value = false"
                    @delete="deleteUser(user.id, $event); editUserDialog.value = false"
                  />
                </template>
              </v-dialog>

              <div class="mt-2">
                {{ user.attributes.name }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import {
  ref,
  computed,
  getCurrentInstance,
  onMounted,
} from '@vue/composition-api'
import { groupBy, findIndex } from 'lodash'
import { mdiPlus, mdiTrashCanOutline } from '@mdi/js'
import useCompInit from '@/views/composable/useCompInit'
import UserApi from '@/api/waiter/User'
import UserSystemFlagApi from '@/api/v2/eod-closing/UserSystemFlag'
import UserForm from './components/UserForm.vue'
import ability from '@/plugins/acl/ability'

export default {
  components: {
    UserForm,
  },
  setup() {
    const vm = getCurrentInstance().proxy

    const currentTabIdx = ref(0)
    const users = ref([])
    const userSystemFlags = ref([])
    const roles = [
      ['cast', 'キャスト'],
      ['waiter', 'ボーイ'],
      ['alliance', 'アライアンス'],
    ]

    const { initWith, isLoading } = useCompInit()

    const currentRole = computed(() => roles[currentTabIdx.value][0])

    const usersByRole = computed(() => {
      return groupBy(users.value, 'attributes.role')
    })

    const canUseNewClosing = computed(() => {
      return ability.can('manage', 'eod_closing')
    })

    const getUsers = async () => {
      isLoading.value = true

      const res = await UserApi.getUsers()

      if (res?.data) {
        users.value = [...res.data.users.data]
      }

      isLoading.value = false
    }

    const getUserSystemFlags = async () => {
      if (!canUseNewClosing.value) return

      const res = await UserSystemFlagApi.getUserSystemFlags()

      if (res.data.status === 'success') {
        userSystemFlags.value = [...res.data.data.data]
      }
    }

    const createUser = async ({
      name,
      profileImage,
      userSystemFlaggingsAttributes = [],
      callback,
    }) => {
      try {
        const res = await UserApi.createUser({
          name,
          role: currentRole.value,
          profileImage,
          userSystemFlaggingsAttributes,
        })
        if (res.data) {
          users.value = [...res.data.users.data]
          vm.$toast.success('ユーザーを新規作成しました')
          getUserSystemFlags()
        }
      } catch (error) {
        error.response?.data?.message?.forEach(msg => {
          vm.$toast.error(msg)
        })
      } finally {
        callback()
      }
    }

    const updateUser = async ({
      id,
      name,
      profileImage,
      userSystemFlaggingsAttributes = [],
      callback,
    }) => {
      try {
        const res = await UserApi.updateUser({
          id,
          name,
          profileImage,
          userSystemFlaggingsAttributes,
        })
        if (res.data) {
          const updatedUser = res.data.users.data
          const userIdx = findIndex(users.value, user => Number(user.id) === Number(updatedUser.id))
          users.value.splice(userIdx, 1, updatedUser)
          vm.$toast.success('ユーザーを更新しました')
          getUserSystemFlags()
        }
      } catch (error) {
        error.response?.data?.message?.forEach(msg => {
          vm.$toast.error(msg)
        })
      } finally {
        callback()
      }
    }

    const deleteUser = async (id, callback) => {
      try {
        const res = await UserApi.deleteUser({ id })

        if (res?.status === 200) {
          const userIdx = findIndex(users.value, user => Number(user.id) === Number(id))
          users.value.splice(userIdx, 1)
          vm.$toast.success('ユーザーを削除しました')
        }
      } catch (e) {
        // TODO
      } finally {
        callback()
      }
    }

    onMounted(() => {
      initWith([
        getUsers(),
        getUserSystemFlags(),
      ])
    })

    return {
      // data
      roles,
      currentTabIdx,
      isLoading,
      users,
      userSystemFlags,

      // computed
      usersByRole,
      currentRole,

      // methods
      createUser,
      updateUser,
      deleteUser,

      icons: {
        mdiPlus,
        mdiTrashCanOutline,
      },
    }
  },
}
</script>
