<!-- eslint-disable vue/no-deprecated-v-bind-sync vue/v-on-event-hyphenation -->
<template>
  <div>
    <template v-if="hasClubReports && hasUserReports">
      <v-tabs
        v-model="tab"
        background-color="transparent"
        class="elevation-0"
      >
        <v-tab>店舗</v-tab>
        <v-tab>ユーザー</v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
        style="background: transparent"
        touchless
      >
        <v-tab-item v-if="isLoading">
          <v-skeleton-loader type="table" />
        </v-tab-item>

        <template v-else>
          <v-tab-item>
            <v-row>
              <v-col cols="12">
                <ClubReportTable
                  v-if="reportsMap[selectedClubReportId]"
                  :key="`report-${selectedClubReportId}`"
                  :selected-club-report-id.sync="selectedClubReportId"
                  :display-mode="displayModeMap[selectedClubReportId]"
                  :loading="loadingStatuses[selectedClubReportId]"
                  :report="reportsMap[selectedClubReportId]"
                  :club-reports="clubReports"
                  @update:selectedClubReportId="getClubReportResults($event)"
                  @update:displayMode="onChangeClubReportDisplayMode(selectedClubReportId, $event)"
                  @reload="getClubReportResults(selectedClubReportId)"
                  @save="saveResult"
                />
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <v-row>
              <v-col cols="12">
                <UserReportTable
                  v-if="reportsMap[selectedUserReportId]"
                  :key="`report-${selectedClubReportId}`"
                  :selected-user-report-id.sync="selectedUserReportId"
                  :display-mode.sync="displayModeMap[selectedUserReportId]"
                  :user-id.sync="selectedUserIdMap[selectedUserReportId]"
                  :loading="loadingStatuses[selectedUserReportId]"
                  :report="reportsMap[selectedUserReportId]"
                  :user-reports="userReports"
                  :user-report="userReportsMap[selectedUserReportId]"
                  :available-users="availableUsersMap[selectedUserReportId]"
                  @update:selectedUserReportId="getUserReportResults(selectedUserReportId)"
                  @change-display="getUserReportResults(selectedUserReportId)"
                  @fetch-available-users="handleFetchAvailableUsers(selectedUserReportId, $event)"
                  @create-report-user="createReportUser(selectedUserReportId, $event)"
                  @destroy-report-user="destroyReportUser(selectedUserReportId, $event)"
                  @reload="getUserReportResults(selectedUserReportId)"
                  @save="saveResult"
                />
              </v-col>
            </v-row>
          </v-tab-item>
        </template>
      </v-tabs-items>
    </template>

    <template v-else-if="hasClubReports && !hasUserReports">
      <v-skeleton-loader
        v-if="isLoading"
        type="table"
      />
      <v-row v-else>
        <v-col cols="12">
          <ClubReportTable
            v-if="reportsMap[selectedClubReportId]"
            :key="`report-${selectedClubReportId}`"
            :selected-club-report-id.sync="selectedClubReportId"
            :display-mode="displayModeMap[selectedClubReportId]"
            :loading="loadingStatuses[selectedClubReportId]"
            :report="reportsMap[selectedClubReportId]"
            :club-reports="clubReports"
            @update:selectedClubReportId="getClubReportResults($event)"
            @update:displayMode="onChangeClubReportDisplayMode(selectedClubReportId, $event)"
            @reload="getClubReportResults(selectedClubReportId)"
            @save="saveResult"
          />
        </v-col>
      </v-row>
    </template>

    <template v-else-if="hasUserReports && !hasClubReports">
      <v-skeleton-loader
        v-if="isLoading"
        type="table"
      />
      <v-row v-else>
        <v-col cols="12">
          <UserReportTable
            v-if="reportsMap[selectedUserReportId]"
            :key="`report-${selectedClubReportId}`"
            :selected-user-report-id.sync="selectedUserReportId"
            :display-mode.sync="displayModeMap[selectedUserReportId]"
            :user-id.sync="selectedUserIdMap[selectedUserReportId]"
            :loading="loadingStatuses[selectedUserReportId]"
            :report="reportsMap[selectedUserReportId]"
            :user-reports="userReports"
            :user-report="userReportsMap[selectedUserReportId]"
            :available-users="availableUsersMap[selectedUserReportId]"
            @update:selectedUserReportId="getUserReportResults(selectedUserReportId)"
            @change-display="getUserReportResults(selectedUserReportId)"
            @fetch-available-users="handleFetchAvailableUsers(selectedUserReportId, $event)"
            @create-report-user="createReportUser(selectedUserReportId, $event)"
            @destroy-report-user="destroyReportUser(selectedUserReportId, $event)"
            @reload="getUserReportResults(selectedUserReportId)"
            @save="saveResult"
          />
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <v-skeleton-loader
        v-if="isLoading"
        type="table"
      />
      <p v-else>
        データがありません
      </p>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  ref,
  reactive,
  computed,
  onMounted,
  toRefs,
} from '@vue/composition-api'
import useCompInit from '@/views/composable/useCompInit'
import ClubReportApi from '@/api/v2/eod-report/ClubReport'
import UserReportApi from '@/api/v2/eod-report/UserReport'
import BusinessRuleResultApi from '@/api/v2/eod-report/BusinessRuleResult'
import ReportUserApi from '@/api/v2/eod-report/ReportUser'
import ClubReportTable from './ClubReportTable.vue'
import UserReportTable from './UserReportTable.vue'

export default {
  components: {
    ClubReportTable,
    UserReportTable,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { date } = toRefs(props)

    const tab = ref(0)
    const clubReports = ref([])
    const userReports = ref([])
    const selectedClubReportId = ref(null)
    const selectedUserReportId = ref(null)
    const reportsMap = ref({})
    const availableUsersMap = ref({})
    const displayModeMap = reactive({})
    const selectedUserIdMap = reactive({})
    const loadingStatuses = reactive({})

    const hasClubReports = computed(() => clubReports.value.length > 0)
    const clubReportsMap = computed(() => clubReports.value.reduce((acc, cr) => ({ ...acc, [cr.attributes.id]: cr }), {}))
    const hasUserReports = computed(() => userReports.value.length > 0)
    const userReportsMap = computed(() => userReports.value.reduce((acc, ur) => ({ ...acc, [ur.attributes.id]: ur }), {}))

    const { initWith, isLoading } = useCompInit()

    const getClubReportResults = async clubReportId => {
      try {
        Vue.set(loadingStatuses, clubReportId, true)
        const displayMode = displayModeMap[clubReportId]
        const res = await ClubReportApi.getClubReport(clubReportId, date.value, displayMode)
        if (res.data.status === 'success') {
          const { data } = res.data.data
          reportsMap.value = { ...reportsMap.value, [clubReportId]: data }
        }
      } finally {
        Vue.set(loadingStatuses, clubReportId, false)
      }
    }

    const getUserReportResults = async userReportId => {
      try {
        Vue.set(loadingStatuses, userReportId, true)
        const userId = selectedUserIdMap[userReportId]
        const displayMode = displayModeMap[userReportId]
        const res = await UserReportApi.getUserReport(userReportId, date.value, userId, displayMode)
        if (res.data.status === 'success') {
          const { data } = res.data.data
          reportsMap.value = { ...reportsMap.value, [userReportId]: data }
        }
      } finally {
        Vue.set(loadingStatuses, userReportId, false)
      }
    }

    const getClubReports = async () => {
      const res = await ClubReportApi.getClubReports()

      if (res.data.status === 'success') {
        const { data } = res.data.data
        clubReports.value = data
        data.forEach(async cr => {
          Vue.set(loadingStatuses, cr.attributes.id, false)
          Vue.set(displayModeMap, cr.attributes.id, 'monthly_daily')
        })
        // 最初の１件を初期値としてセット
        if (data.length > 0) {
          selectedClubReportId.value = data[0].attributes.id
          getClubReportResults(data[0].attributes.id)
        }
      }
    }

    const getUserReports = async () => {
      const res = await UserReportApi.getUserReports()

      if (res.data.status === 'success') {
        const { data } = res.data.data
        userReports.value = data
        data.forEach(async ur => {
          Vue.set(loadingStatuses, ur.attributes.id, false)
          Vue.set(displayModeMap, ur.attributes.id, 'monthly_all_users_sum')
          Vue.set(selectedUserIdMap, ur.attributes.id, null)
          availableUsersMap.value = { ...availableUsersMap.value, [ur.attributes.id]: [] }
        })
        // 最初の１件を初期値としてセット
        if (data.length > 0) {
          selectedUserReportId.value = data[0].attributes.id
          getUserReportResults(data[0].attributes.id)
        }
      }
    }

    const onChangeClubReportDisplayMode = (clubReportId, displayMode) => {
      displayModeMap[clubReportId] = displayMode
      getClubReportResults(clubReportId)
    }

    const createReportUser = async (
      id,
      { userId, callback } = { userId: null, callback: () => {} },
    ) => {
      console.log('createReportUser')
      try {
        const res = await ReportUserApi.createReportUser(id, userId)
        if (res.data.status === 'success') {
          getUserReportResults(id)

          const { data } = res.data.data
          const urIdx = userReports.value.findIndex(ur => ur.attributes.id === Number(id))
          if (urIdx < 0) return

          const updatedUrData = { ...userReports.value[urIdx] }
          updatedUrData.attributes.reportUsers.data.push(data)
          userReports.value.splice(urIdx, 1, updatedUrData)

          const updatedAvailableUsers = [...availableUsersMap.value[id]]
          const userIdx = updatedAvailableUsers.findIndex(user => user.attributes.id === Number(userId))
          if (userIdx < 0) return

          updatedAvailableUsers.splice(userIdx, 1)
          availableUsersMap.value = { ...availableUsersMap.value, [id]: updatedAvailableUsers }
        }
      } catch (error) {
        // TODO エラーハンドリング
      } finally {
        callback()
      }
    }

    const destroyReportUser = async (
      userReportId,
      { id, userId, callback } = { id: null, userId: null, callback: () => {} },
    ) => {
      console.log('destroyReportUser')
      try {
        const res = await ReportUserApi.destroyReportUser(id)
        if (res.data.status === 'success') {
          getUserReportResults(userReportId)

          const { data } = res.data.data

          const urIdx = userReports.value.findIndex(ur => ur.attributes.id === Number(userReportId))
          if (urIdx < 0) return

          const updatedUrData = { ...userReports.value[urIdx] }
          const ruIdx = updatedUrData.attributes.reportUsers.data.findIndex(reportUser => reportUser.attributes.id === Number(id))
          if (ruIdx < 0) return

          updatedUrData.attributes.reportUsers.data.splice(ruIdx, 1)
          userReports.value.splice(urIdx, 1, updatedUrData)

          const updatedAvailableUsers = [...availableUsersMap.value[id]]
          const userIdx = updatedAvailableUsers.findIndex(user => user.attributes.id === Number(userId))
          if (userIdx < 0) return

          updatedAvailableUsers.splice(userIdx, 1, data)
          availableUsersMap.value = { ...availableUsersMap.value, [id]: updatedAvailableUsers }
        }
      } catch (error) {
        // TODO エラーハンドリング
      } finally {
        callback()
      }
    }

    const handleFetchAvailableUsers = async (id, callback = () => {}) => {
      try {
        const res = await UserReportApi.getAvailableUsers(id)
        if (res.data.status === 'success') {
          const { data } = res.data.data
          availableUsersMap.value = { ...availableUsersMap.value, [id]: data }
        }
      } catch (error) {
        // TODO エラーハンドリング
      } finally {
        callback()
      }
    }

    const updateReportsMap = (aggregationId, returnedResults) => {
      console.log(aggregationId, returnedResults)
      const updatedReport = { ...reportsMap.value[aggregationId] }
      console.log('updatedReport', updatedReport)

      const newContexts = updatedReport.attributes.contexts.data.map(context => {
        console.log('context', context)
        const newResultsData = context.attributes.results.data.map(r => {
          const matchedResult = returnedResults.find(rr => {
            return rr.attributes.businessRuleId === r.attributes.businessRuleId
              && rr.attributes.contextType === context.attributes.contextType
              && rr.attributes.contextId === context.attributes.id
          })
          console.log('matchedResult', matchedResult)

          if (matchedResult) {
            console.log('MATCHED')
            const { resultValue: newVal, businessDate: newDate, id: newResId } = matchedResult.attributes
            const newDailyArray = r.attributes.value.map(d => {
              if (d.date === newDate) {
                console.log({
                  ...d,
                  value: newVal,
                  businessRuleResultId: newResId,
                })

                return {
                  ...d,
                  value: newVal,
                  businessRuleResultId: newResId,
                }
              }

              return d
            })

            console.log({
              ...r,
              attributes: {
                ...r.attributes,
                value: newDailyArray,
              },
            })

            return {
              ...r,
              attributes: {
                ...r.attributes,
                value: newDailyArray,
              },
            }
          }

          return r
        })

        return {
          ...context,
          attributes: {
            ...context.attributes,
            results: {
              ...context.attributes.results,
              data: newResultsData,
            },
          },
        }
      })

      updatedReport.attributes.contexts.data = newContexts

      return {
        ...reportsMap,
        [aggregationId]: updatedReport,
      }
    }

    const saveResult = async ({
      aggregationId,
      businessDate,
      businessRuleId,
      contextType,
      contextId,
      resultValue,
    }) => {
      try {
        const res = await BusinessRuleResultApi.save({
          businessDate,
          businessRuleId,
          contextType,
          contextId,
          resultValue,
        })
        console.log('res', res)
        if (res.data.status === 'success') {
          const { data } = res.data.data
          console.log('success', data)
          updateReportsMap(aggregationId, data)
        }
      } catch (error) {
        // TODO エラーハンドリング
      }
    }

    onMounted(() => {
      initWith([
        getClubReports(),
        getUserReports(),
      ])
    })

    return {
      tab,
      clubReports,
      userReports,
      selectedClubReportId,
      selectedUserReportId,
      isLoading,
      loadingStatuses,
      hasClubReports,
      hasUserReports,

      clubReportsMap,
      userReportsMap,
      selectedUserIdMap,
      reportsMap,
      displayModeMap,
      availableUsersMap,

      getClubReportResults,
      onChangeClubReportDisplayMode,
      getUserReportResults,
      createReportUser,
      destroyReportUser,
      handleFetchAvailableUsers,
      saveResult,
      userReportDisplayModes: [
        {
          text: '全ユーザー/月別合計',
          value: 'monthly_all_users_sum',
        },
        {
          text: 'ユーザー別/月別日次',
          value: 'monthly_daily',
        },
        {
          text: 'ユーザー別/月別合計',
          value: 'monthly_sum',
        },
      ],
    }
  },
}
</script>
