<template>
  <v-data-table
    :loading="loading"
    :headers="aggregationTable.headers"
    :items="aggregationTable.items"
    item-key="date"
    :header-props="{ sortByText: 'ソート' }"
    :footer-props="{ itemsPerPageText: '行/ページ:' }"
    no-data-text="データがありません"
    loading-text="読込中..."
    hide-default-footer
    :items-per-page="-1"
    class="aggregation-table fixed-first-column"
  >
    <template #top>
      <v-toolbar
        flat
        color="transparent"
      >
        <slot
          name="toolbar-title"
          :aggregation="aggregation"
        />

        <v-spacer />
        <slot name="toolbar-filters" />
        <v-btn
          icon
          depressed
          :ripple="false"
          :loading="loading"
          :disabled="loading"
          @click.prevent="$emit('reload', aggregationTable.id)"
        >
          <v-icon>
            {{ icons.mdiReload }}
          </v-icon>
        </v-btn>

        <template v-if="$slots['toolbar-menu']">
          <v-divider
            vertical
            inset
            class="mx-2"
          />
          <slot name="toolbar-menu" />
        </template>
      </v-toolbar>
    </template>
    <!-- eslint-disable-next-line vue/no-useless-template-attributes -->
    <template
      v-for="(header, headerIdx) in aggregationTable.businessRuleHeaders"
      #[`item.${header.value}`]="{ item }"
    >
      <v-menu
        :key="`business-rule-result-input-for-current-club-business-rule-id-${header.value}-${headerIdx}`"
        :close-on-content-click="false"
        :nudge-width="200"
        :disabled="isNotEditable(item, header.value)"
        @input="onMenuInput($event, item, header.value)"
      >
        <template #activator="{ on: editMenuOn, attrs: editMenuAttrs }">
          <span
            v-bind="editMenuAttrs"
            v-on="editMenuOn"
          >
            <v-checkbox
              v-if="isBooleanItem(item, header.value)"
              :input-value="item[header.value]"
              :disabled="loading"
              readonly
              dense
              hide-details
              :class="{ 'new-item': isNew(item, header.value) }"
            />
            <v-text-field
              v-else-if="isNumberItem(item, header.value)"
              :value="numberText(item, header.value)"
              :disabled="loading"
              readonly
              dense
              hide-details
              :class="{ 'new-item': isNew(item, header.value) }"
            />
            <v-text-field
              v-else
              :value="item[header.value]"
              :disabled="loading"
              :type="inputType(item, header.value)"
              readonly
              dense
              hide-details
              :class="{ 'new-item': isNew(item, header.value) }"
            />
          </span>
        </template>
        <template #default="editMenu">
          <v-card>
            <v-card-subtitle>{{ header.text }}</v-card-subtitle>
            <v-card-text>
              <v-checkbox
                v-if="isBooleanItem(item, header.value)"
                v-model="item[header.value]"
                autofocus
                dense
                hide-details
                :disabled="isNotEditable(item, header.value)"
              />
              <v-text-field
                v-else-if="isNumberItem(item, header.value)"
                v-model="item[header.value]"
                v-format-number-on-blur
                autofocus
                type="number"
                inputmode="numeric"
                pattern="[0-9]*"
                dense
                hide-details
                :disabled="isNotEditable(item, header.value)"
              />
              <v-text-field
                v-else
                v-model="item[header.value]"
                autofocus
                :type="inputType(item, header.value)"
                dense
                hide-details
                :disabled="isNotEditable(item, header.value)"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                text
                small
                @click="editMenu.value = false"
              >
                キャンセル
              </v-btn>
              <v-btn
                color="primary"
                small
                :disabled="isNotEditable(item, header.value)"
                @click="saveResultValue(item, header.value, item[header.value], editMenu);"
              >
                保存
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
import { computed, toRefs } from '@vue/composition-api'
import { mdiReload, mdiDotsVertical } from '@mdi/js'
import useRangeDailyAggregationTable from '../composables/useRangeDailyAggregationTable'
import formatNumberOnBlur from '@/directives/formatNumberOnBlur'

export default {
  directives: {
    formatNumberOnBlur,
  },
  props: {
    aggregation: {
      type: Object,
      required: true,
      default: () => {
        return {
          attributes: {
            id: null,
            name: '',
            description: '',
            businessRules: { data: [] },
            results: {
              data: [{
                date: null,
                value: null,
                businessRuleResultId: null,
              }],
            },
            contexts: { data: [{ attributes: { results: { data: [] } } }] },
          },
        }
      },
    },
    aggregationUsers: {
      type: Array,
      default: () => [],
    },
    availableUsers: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { aggregation } = toRefs(props)

    const {
      aggregationTable,
      isUserAggregation,
      isBooleanItem,
      isNumberItem,
      isNew,
      isNotEditable,
      inputType,
      numberText,
      saveResultValue,
      onMenuInput,
    } = useRangeDailyAggregationTable(props, emit)

    const isAllSaved = computed(() => {
      return aggregation.value.attributes.contexts.data.every(context => {
        return context.attributes.results.data.every(result => {
          return !!result.attributes.businessRuleResultId
        })
      })
    })

    return {
      isBooleanItem,
      isNumberItem,
      isNotEditable,
      isNew,
      isAllSaved,
      isUserAggregation,
      inputType,
      numberText,
      aggregationTable,
      saveResultValue,
      onMenuInput,
      icons: {
        mdiReload,
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

@include theme--child(aggregation-table) using ($material) {
  th.fixed-cell,
  td.fixed-cell {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 3;
    background: map-deep-get($material, 'app-bar');
  }

  th.fixed-cell {
    z-index: 4;
  }

  .v-progress-linear {
    z-index: 5;
  }
}

.aggregation-table {
  white-space: nowrap;

  /* 新規作成のフィールドに適用するスタイル */
  .new-item .v-input__slot::before {
    border-bottom: 2px solid #9155FD !important; /* アンダーラインの色を変更（例: 青色） */
  }

  .new-item .v-input__slot::after {
    border-bottom: 2px solid #9155FD !important; /* フォーカス時のアンダーラインの色を変更（例: 濃い青色） */
  }

  /* チェックボックスのアンダーラインも変更 */
  .new-item .v-input--selection-controls__input:after {
    border-bottom: 2px solid #9155FD !important;
  }

  .new-item .v-input--selection-controls__input:focus:after {
    border-bottom: 2px solid #9155FD !important;
  }
}
</style>
