<template>
  <v-row>
    <v-spacer />
    <v-col>
      <v-select
        v-model="displayModeModel"
        :items="displayModes"
        hide-details
      />
    </v-col>
  </v-row>
</template>

<script>
import { useVModel } from '@vueuse/core'

export default {
  props: {
    displayMode: {
      type: String,
      required: true,
      default: 'monthly_daily',
    },
  },
  setup(props, { emit }) {
    const displayModeModel = useVModel(props, 'displayMode', emit)

    return {
      displayModeModel,
      displayModes: [
        {
          text: '月別日次',
          value: 'monthly_daily',
        },
        {
          text: '月別合計',
          value: 'monthly_sum',
        },
      ],
    }
  },
}
</script>
