var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('div',[_c('v-skeleton-loader',{staticClass:"mb-8",attrs:{"type":"table-heading"}}),_c('v-row',_vm._l((36),function(loadingIdx){return _c('v-col',{key:("loading" + loadingIdx),staticClass:"my-2 d-flex align-center justify-center",attrs:{"md":"3","sm":"3","cols":"4"}},[_c('v-skeleton-loader',{attrs:{"type":"avatar","size":"60"}})],1)}),1)],1):_c('div',[_c('div',{staticClass:"pa-3 w-full d-flex align-center justify-end"},[_c('v-dialog',{attrs:{"width":"500","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var newUserOn = ref.on;
var newUserAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"primary","ripple":false}},'v-btn',newUserAttrs,false),newUserOn),[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.icons.mdiPlus)}})],1)]}},{key:"default",fn:function(newUserDialog){return [_c('UserForm',{attrs:{"role":_vm.currentRole,"user-system-flags":_vm.userSystemFlags},on:{"submit":function($event){_vm.createUser($event); newUserDialog.value = false},"cancel":function($event){newUserDialog.value = false}}})]}}])})],1),(_vm.users.length > 1)?_c('v-tabs',{staticClass:"mb-6 elevation-0",attrs:{"show-arrows":"","background-color":"transparent"},model:{value:(_vm.currentTabIdx),callback:function ($$v) {_vm.currentTabIdx=$$v},expression:"currentTabIdx"}},_vm._l((_vm.roles),function(role){return _c('v-tab',{key:("v-tab-" + (role[0]))},[_vm._v(" "+_vm._s(role[1])+" ")])}),1):_vm._e(),_c('v-tabs-items',{staticStyle:{"background":"transparent"},attrs:{"touchless":""},model:{value:(_vm.currentTabIdx),callback:function ($$v) {_vm.currentTabIdx=$$v},expression:"currentTabIdx"}},_vm._l((_vm.roles),function(role){return _c('v-tab-item',{key:("v-tab-item-" + (role[0]))},[_c('v-row',{staticClass:"pb-5"},_vm._l((_vm.usersByRole[role[0]]),function(user){return _c('v-col',{key:("user" + (user.id)),staticClass:"my-2",attrs:{"md":"3","sm":"3","cols":"4"}},[_c('div',{staticClass:"d-flex flex-column align-center mb-4"},[_c('v-dialog',{attrs:{"width":"500","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var persistedUserOn = ref.on;
var persistedUserAttrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"cursor-pointer",class:{ 'elevation-4' : hover },attrs:{"size":"60","color":"secondary"}},'v-avatar',persistedUserAttrs,false),persistedUserOn),[(user.attributes.profileImage)?_c('v-img',{attrs:{"src":user.attributes.profileImage}}):_c('span',{staticClass:"text-xs font-weight-bold"},[_vm._v(" "+_vm._s(user.attributes.name.substring(0, 3))+" ")])],1)]}}],null,true)})]}},{key:"default",fn:function(editUserDialog){return [_c('UserForm',{attrs:{"user":user,"role":_vm.currentRole,"user-system-flags":_vm.userSystemFlags},on:{"submit":function($event){_vm.updateUser($event); editUserDialog.value = false},"cancel":function($event){editUserDialog.value = false},"delete":function($event){_vm.deleteUser(user.id, $event); editUserDialog.value = false}}})]}}],null,true)}),_c('div',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(user.attributes.name)+" ")])],1)])}),1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }