<template>
  <v-container>
    <v-row class="no-print">
      <v-col>
        <v-menu
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="dates.join(' ~ ')"
              :prepend-icon="icons.mdiCalendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="dates"
            locale="ja"
            :day-format="date => new Date(date).getDate()"
            color="primary"
            range
            no-title
            @change="getPayroll(selectedContainerId)"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row class="no-print">
      <v-col cols="5">
        <v-select
          v-model="selectedContainerId"
          :items="containers"
          item-text="attributes.name"
          item-value="attributes.id"
          dense
          hide-details
          no-data-text="データがありません"
          @change="getPayroll(selectedContainerId)"
        ></v-select>
      </v-col>
    </v-row>

    <template v-if="isLoading || loadingStatuses[selectedContainerId]">
      <v-row
        v-for="n in 10"
        :key="n"
        class="user-section"
      >
        <v-col>
          <v-skeleton-loader type="table-heading, list-item-two-line" />
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <template v-if="containersMap[selectedContainerId]">
        <v-row
          v-for="user in containersMap[selectedContainerId]"
          :key="user.id"
          class="user-section"
        >
          <v-col>
            <PayrollContainer
              :container="user.attributes.payroll.data"
              :user="user"
              :start-date="dates[0]"
            />
          </v-col>
        </v-row>
      </template>
    </template>
  </v-container>
</template>

<script>
import Vue from 'vue'
import {
  ref,
  onMounted,
  reactive,
  inject,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { parseISO, startOfMonth, format } from 'date-fns'
import { mdiCalendar } from '@mdi/js'
import PayrollContainer from './PayrollContainer.vue'
import PayrollAggregationContainerApi from '@/api/v2/employee/payroll/PayrollAggregationContainer'
import TimeApi from '@/api/v2/utility/Time'

export default {
  components: {
    PayrollContainer,
  },
  setup() {
    const currentClubMeta = inject('currentClubMeta', { today: null })
    const { route, router } = useRouter()
    const today = currentClubMeta.today || new Date().toISOString().slice(0, 10)
    const date = ref(route.value.query.date || today)
    const startDate = ref(format(startOfMonth(parseISO(date.value)), 'yyyy-MM-dd'))
    const dates = ref([startDate.value, date.value])
    const isLoading = ref(false)

    const interval = ref('monthly')

    const containers = ref([])
    const containersMap = ref({})
    const payrollPerUser = ref([])
    const selectedContainerId = ref(null)
    const loadingStatuses = reactive({})

    // eslint-disable-next-line no-shadow
    const getPayroll = async (id, date, startDate, interval = 'monthly') => {
      try {
        Vue.set(loadingStatuses, id, true)
        const res = await PayrollAggregationContainerApi.getPayrollContainer(id, {
          date: dates.value[1],
          startDate: dates.value[0],
          interval,
        })
        if (res.data.status === 'success') {
          const { data } = res.data.data
          containersMap.value = { ...containersMap.value, [id]: data }
        }
      } finally {
        Vue.set(loadingStatuses, id, false)
      }
    }

    const getPayrollContainers = async () => {
      try {
        isLoading.value = true
        const res = await PayrollAggregationContainerApi.getPayrollContainers()
        if (res.data.status === 'success') {
          const { data } = res.data.data
          containers.value = data
          data.forEach(async cr => {
            Vue.set(loadingStatuses, cr.attributes.id, false)
          })
          // 最初の１件を初期値としてセット
          if (data.length > 0) {
            selectedContainerId.value = data[0].attributes.id
            getPayroll(data[0].attributes.id)
          }
        }
      } finally {
        isLoading.value = false
      }
    }

    const getToday = async () => {
      const res = await TimeApi.getToday()

      if (res.data.status === 'success') {
        date.value = res.data.data
      }
    }

    onMounted(() => {
      getPayrollContainers()
      getToday()
    })

    // const update = async id => {
    //   isSubmitting.value = true

    //   const res = await BalanceReceivableApi.updateBalanceReceivable({
    //     id,
    //     ...editTarget.value,
    //   })

    //   if (res?.status === 200) {
    //     const index = balanceReceivables.value.findIndex(o => +o.id === +id)
    //     balanceReceivables.value.splice(index, 1, res.data.data)
    //     vm.$toast.success('売掛を更新しました')
    //   }

    //   isSubmitting.value = false
    // }

    return {
      containers,
      payrollPerUser,
      containersMap,
      selectedContainerId,
      loadingStatuses,
      getPayroll,
      interval,
      dates,
      isLoading,
      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>
