import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getExpenses(date) {
    return axiosIns.get(
      '/v2/eod_closing/expenses',
      {
        params: { date },
      },
    )
  },
  createExpense({
    businessDate,
    amount,
    expensePrimaryLabelId,
    expenseSecondaryLabelId = null,
    expenseTertiaryLabelId = null,
    expenseQuaternaryLabelId = null,
    meta = {},
  }) {
    return axiosIns.post(
      '/v2/eod_closing/expenses',
      snakecaseKeys({
        businessDate,
        amount,
        expensePrimaryLabelId,
        expenseSecondaryLabelId,
        expenseTertiaryLabelId,
        expenseQuaternaryLabelId,
        meta,
      }),
    )
  },
  updateExpense(id, {
    amount = null,
    expensePrimaryLabelId = null,
    expenseSecondaryLabelId = null,
    expenseTertiaryLabelId = null,
    expenseQuaternaryLabelId = null,
    meta = {},
  }) {
    return axiosIns.put(
      `/v2/eod_closing/expenses/${id}`,
      snakecaseKeys({
        amount,
        expensePrimaryLabelId,
        expenseSecondaryLabelId,
        expenseTertiaryLabelId,
        expenseQuaternaryLabelId,
        meta,
      }),
    )
  },
  destroyExpense(id) {
    return axiosIns.delete(`/v2/eod_closing/expenses/${id}`)
  },
}
