const formatNumberOnBlur = {
  inserted(el, _binding, _vnode) {
    const input = el.querySelector('input') || el
    if (!input) return

    const handleBlur = () => {
      let val = input.value
      if (val === '0') return

      val = val.replace(/^0+/, '')
      if (val.startsWith('.')) {
        val = `0${val}`
      }
      input.value = val === '' ? '0' : val

      // v-modelと同期させるためinputイベント発火
      const evt = new Event('input', { bubbles: true })
      input.dispatchEvent(evt)
    }

    input.addEventListener('blur', handleBlur)
  },
}

export default formatNumberOnBlur
