import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  save({
    businessDate,
    businessRuleId,
    contextType,
    contextId,
    resultValue,
  }) {
    return axiosIns.put(
      '/v2/eod_report/business_rule_results/save',
      snakecaseKeys({
        businessDate,
        businessRuleId,
        contextType,
        contextId,
        resultValue,
      }),
    )
  },
}
