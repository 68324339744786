var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isMounting)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.items,"no-data-text":"データがありません","loading-text":"読込中...","header-props":{ sortByText: 'ソート' },"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v("諸経費")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","ripple":false,"small":""},on:{"click":_vm.createExpenseRow}},[_c('v-icon',{attrs:{"left":"","small":""},domProps:{"textContent":_vm._s(_vm.icons.mdiPlus)}}),_vm._v(" 追加 ")],1),_c('v-btn',{staticClass:"ml-2 mr-1",attrs:{"icon":"","depressed":"","ripple":false,"loading":_vm.isLoading},on:{"click":_vm.reloadExpenses}},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.icons.mdiReload)}})],1),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":"","inset":""}}),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menuOn = ref.on;
var menuAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',menuAttrs,false),menuOn),[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.icons.mdiDotsVertical)}})],1)]}},{key:"default",fn:function(menu){return [_c('v-list',{attrs:{"disabled":_vm.loading,"dense":""}},[_c('v-subheader',[_vm._v("操作")]),_c('v-list-item',{on:{"click":function($event){_vm.showTertiaryColumns = !_vm.showTertiaryColumns; menu.value = false}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.showTertiaryColumns ? '詳細分類を非表示' : '詳細分類を表示')+" ")])],1)],1)]}}])})],1)]},proxy:true},_vm._l(([
        {
          items: _vm.primaryLabels,
          key: 'expensePrimaryLabelId',
          placeholder: '選択してください',
          required: true,
        },
        { items: _vm.secondaryLabels, key: 'expenseSecondaryLabelId' },
        { items: _vm.tertiaryLabels, key: 'expenseTertiaryLabelId' },
        { items: _vm.quaternaryLabels, key: 'expenseQuaternaryLabelId' } ]),function(obj){return {key:("item." + (obj.key)),fn:function(ref){
      var item = ref.item;
return [_c('v-combobox',{key:("combobox-" + (obj.key) + "-" + (item.id)),attrs:{"value":_vm.selectedLabelName(item, obj.key),"items":obj.items,"item-text":"attributes.name","placeholder":obj.placeholder,"clearable":!obj.required,"dense":"","hide-details":"","menu-props":{ auto: true, offsetY: true }},on:{"change":function($event){return _vm.onChangeLabel($event, item, obj.key)}}})]}}}),{key:"item.amount",fn:function(ref){
      var item = ref.item;
return [_c('v-text-field',{attrs:{"type":"number","dense":"","hide-details":""},on:{"change":function($event){return _vm.debounceUpdateExpense(item, 'amount')}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_vm._v("¥")]},proxy:true}],null,true),model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", _vm._n($$v))},expression:"item.amount"}})]}},{key:"item.meta.isNonCash",fn:function(ref){
      var item = ref.item;
return [_c('v-checkbox',{attrs:{"hide-details":"","dense":""},on:{"change":function($event){return _vm.updateExpense(item.id, { meta: { isNonCash: $event } })}},model:{value:(item.meta.isNonCash),callback:function ($$v) {_vm.$set(item.meta, "isNonCash", $$v)},expression:"item.meta.isNonCash"}})]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [(_vm.isNew(item))?_c('v-btn',{attrs:{"color":"primary","ripple":false,"disabled":!_vm.isValidExpense(item),"small":""},on:{"click":function($event){return _vm.createExpense(item)}}},[_vm._v(" 保存 ")]):_vm._e(),(!_vm.isNew(item))?_c('v-btn',{attrs:{"icon":"","text":"","ripple":false},on:{"click":function($event){return _vm.destroyExpense(item)}}},[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.icons.mdiTrashCanOutline)}})],1):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }