<!-- eslint-disable vue/no-deprecated-v-bind-sync -->
<template>
  <RangeDailyTable
    v-if="displayModeModel === 'monthly_daily'"
    :aggregation="aggregatable"
    :loading="loading"
    @save="$emit('save', $event)"
    @reload="$emit('reload', $event)"
  >
    <template #toolbar-title="{ aggregation }">
      <ClubReportTableTitle
        v-if="aggregation.attributes"
        :selected-club-report-id.sync="selectedClubReportIdModel"
        :club-reports="clubReports"
        :default-title="aggregation.attributes.name"
      />
    </template>
    <template #toolbar-filters>
      <ClubReportTableFilters
        :display-mode.sync="displayModeModel"
      />
    </template>
  </RangeDailyTable>

  <RangeSumTable
    v-else-if="displayModeModel === 'monthly_sum'"
    :aggregation="aggregatable"
    :loading="loading"
    @reload="$emit('reload', $event)"
  >
    <template #toolbar-title="{ aggregation }">
      <ClubReportTableTitle
        v-if="aggregation.attributes"
        :selected-club-report-id.sync="selectedClubReportIdModel"
        :club-reports="clubReports"
        :default-title="aggregation.attributes.name"
      />
    </template>
    <template #toolbar-filters>
      <ClubReportTableFilters
        :display-mode.sync="displayModeModel"
      />
    </template>
  </RangeSumTable>
</template>

<script>
import {
  computed,
} from '@vue/composition-api'
import { useVModel } from '@vueuse/core'
import ClubReportTableTitle from './ClubReportTableTitle.vue'
import ClubReportTableFilters from './ClubReportTableFilters.vue'
import RangeDailyTable from './RangeDailyTable.vue'
import RangeSumTable from './RangeSumTable.vue'

export default {
  components: {
    RangeDailyTable,
    RangeSumTable,
    ClubReportTableTitle,
    ClubReportTableFilters,
  },
  props: {
    clubReports: {
      type: Array,
      required: true,
      default: () => [],
    },
    displayMode: {
      type: String,
      required: true,
      default: 'monthly_daily',
    },
    selectedClubReportId: {
      type: [String, Number],
      required: true,
    },
    report: {
      type: Object,
      required: true,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const selectedClubReportIdModel = useVModel(props, 'selectedClubReportId', emit)
    const displayModeModel = useVModel(props, 'displayMode', emit)

    const aggregatable = computed(() => {
      if (props.loading) {
        return {
          id: '',
          type: '',
          attributes: {
            businessRules: { data: [] },
            contexts: { data: [] },
            id: '',
            type: '',
            name: '',
            description: '',
          },
        }
      }

      return props.report
    })

    return {
      selectedClubReportIdModel,
      displayModeModel,
      aggregatable,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

@include theme--child(club-report-table) using ($material) {
  th.fixed-cell,
  td.fixed-cell {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 3;
    background: map-deep-get($material, 'app-bar');
  }

  th.fixed-cell {
    z-index: 4;
  }

  .v-progress-linear {
    z-index: 5;
  }
}

.club-report-table {
  white-space: nowrap;
}
</style>
