<template>
  <v-card>
    <v-card-title>
      {{ formTitle }}
      <v-spacer />

      <v-dialog
        v-if="isPersisted"
        width="400"
      >
        <template #activator="{ on: deleteOn, attrs: deleteAttrs }">
          <v-btn
            icon
            :ripple="false"
            v-bind="deleteAttrs"
            v-on="deleteOn"
          >
            <v-icon v-text="icons.mdiTrashCanOutline" />
          </v-btn>
        </template>
        <template #default="deleteConfirmationMenu">
          <v-card>
            <v-card-title>
              削除の確認
            </v-card-title>

            <v-card-text>
              削除を実行してもよろしいですか
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                :ripple="false"
                @click="onCancel(); deleteConfirmationMenu.value = false"
              >
                キャンセル
              </v-btn>
              <v-btn
                :loading="isSubmitting"
                :disabled="isSubmitting"
                :ripple="false"
                color="error"
                @click="onDelete(deleteConfirmationMenu)"
              >
                同意の上で削除
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-card-title>

    <v-card-text>
      <div class="w-hull d-flex align-center justify-center">
        <profile-image-input
          :value="formData.profileImage"
          :change="onSetImage"
          :display-image="displayImage"
          class="my-4"
        />
      </div>
      <v-row class="mt-3">
        <v-col
          sm="12"
          md="6"
        >
          <v-text-field
            v-model="formData.name"
            label="名前"
          />
        </v-col>

        <v-col
          sm="12"
          md="6"
        >
          <v-text-field
            :value="userRoleText"
            disabled
            label="ロール"
          />
        </v-col>
      </v-row>

      <v-row v-if="canUseNewClosing">
        <v-col cols="12">
          <v-combobox
            v-model="selectedFlagNames"
            :items="userSystemFlagNames"
            label="システムフラグ"
            no-data-text="利用可能なデータがありません"
            multiple
            chips
            deletable-chips
            hide-selected
            @input="onComboBoxInput"
          >
          </v-combobox>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        :ripple="false"
        @click="onCancel"
      >
        キャンセル
      </v-btn>
      <v-btn
        color="primary"
        :loading="isSubmitting"
        :disabled="!isValid || isSubmitting"
        :ripple="false"
        @click="onSubmit"
      >
        {{ formSubmitText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  onMounted,
  watch,
} from '@vue/composition-api'
import { mdiTrashCanOutline } from '@mdi/js'
import ability from '@/plugins/acl/ability'
import ProfileImageInput from '@/views/components/util/ProfileImageInput.vue'

export default {
  components: {
    ProfileImageInput,
  },
  props: {
    role: {
      type: String,
      required: true,
      default: '',
    },
    user: {
      type: Object,
      default: () => ({
        id: null,
        attributes: {
          name: '',
          role: '',
          profileImage: '',
          userSystemFlaggings: {
            data: [],
          },
        },
      }),
    },
    userSystemFlags: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const isSubmitting = ref(false)
    const selectedFlagNames = ref([])
    let existingFlagNames = [] // 削除の変更を検知する用
    const removedFlaggingIds = ref([])
    const formData = reactive({
      id: null,
      name: '',
      role: '',
      profileImage: '',
      tempImage: '',
    })

    const isNew = computed(() => !props.user.id)
    const isPersisted = computed(() => !!props.user.id)
    const formTitle = computed(() => (isNew.value ? '新規ユーザー' : 'ユーザー編集'))
    const formSubmitText = computed(() => (isNew.value ? '作成' : '更新'))
    const userRoleText = computed(() => ({ cast: 'キャスト', waiter: 'ボーイ', alliance: 'アイライアンス' }[props.role]))
    const displayImage = computed(() => {
      if (formData.tempImage) return formData.tempImage

      // 既存が文字列ならそのままURL、ファイルならURLに変換してないかも
      return typeof formData.profileImage === 'string' ? formData.profileImage : ''
    })
    const isValid = computed(() => !!formData.name && !!formData.role)
    const canUseNewClosing = computed(() => {
      return ability.can('manage', 'eod_closing')
    })
    const flagIdToNameMap = computed(() => {
      return props.userSystemFlags.reduce((acc, cur) => {
        acc[cur.id] = cur.attributes.name

        return acc
      }, {})
    })
    const flagNameToIdMap = computed(() => {
      return props.userSystemFlags.reduce((acc, cur) => {
        acc[cur.attributes.name] = cur.id

        return acc
      }, {})
    })
    const userSystemFlagNames = computed(() => {
      return props.userSystemFlags.map(flag => flag.attributes.name)
    })

    const initFormData = () => {
      existingFlagNames = []
      selectedFlagNames.value = []
      removedFlaggingIds.value = []

      const { name, profileImage, userSystemFlaggings } = props.user.attributes
      formData.id = props.user.id
      formData.role = props.role
      formData.name = name
      formData.tempImage = profileImage
      formData.profileImage = ''

      userSystemFlaggings.data.forEach(flagging => {
        const flagName = flagIdToNameMap.value[flagging.attributes.userSystemFlagId]
        if (flagName) existingFlagNames.push(flagName)
      })
      selectedFlagNames.value = [...existingFlagNames]
    }

    const onSetImage = file => {
      if (file) {
        formData.profileImage = file
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => formData.tempImage = reader.result

        return
      }

      if (!file && isPersisted.value) {
        formData.profileImage = ''
        formData.tempImage = ''
      }
    }

    const onSubmit = () => {
      if (!isValid.value || isSubmitting.value) return

      isSubmitting.value = true
      const callback = () => {
        initFormData()
        isSubmitting.value = false
      }

      const userSystemFlaggingsAttributes = []
      removedFlaggingIds.value.forEach(id => {
        userSystemFlaggingsAttributes.push({
          id,
          _destroy: true,
        })
      })
      selectedFlagNames.value.forEach(flagName => {
        if (flagNameToIdMap.value[flagName]) {
          if (!existingFlagNames.includes(flagName)) { // 既存フラグなのでスキップ
            userSystemFlaggingsAttributes.push({
              userSystemFlagId: flagNameToIdMap.value[flagName],
            })
          }
        } else { // 新規作成
          userSystemFlaggingsAttributes.push({
            userSystemFlagAttributes: {
              name: flagName,
            },
          })
        }
      })

      emit('submit', {
        ...formData,
        userSystemFlaggingsAttributes,
        callback,
      })
    }

    const onDelete = dialog => {
      if (isSubmitting.value) return

      // eslint-disable-next-line no-param-reassign
      dialog.value = false
      isSubmitting.value = true
      const callback = () => {
        initFormData()
        isSubmitting.value = false
      }
      emit('delete', callback)
    }

    const onCancel = () => {
      initFormData()
      emit('cancel')
    }

    const onComboBoxInput = newSelectedFlagNames => {
      const removed = existingFlagNames.filter(x => !newSelectedFlagNames.includes(x))

      // ---- 「削除されたフラグ」から user_system_flagging.id を特定し removedFlaggingIds に加える ----
      removed.forEach(flagName => {
        const fId = flagNameToIdMap.value[flagName]
        if (!fId) return // 新規フラグだったなら id 不明 => 削除不要

        // userSystemFlaggings.data から user_system_flagging の id を探す
        const match = props.user.attributes.userSystemFlaggings.data.find(flagging => flagging.attributes.userSystemFlagId === Number(fId))
        if (match) {
          // 既存のフラグなので削除フラグに追加
          removedFlaggingIds.value.push(match.id)
        }
      })

      // existingFlagNames = [...newSelectedFlagNames]
      selectedFlagNames.value = newSelectedFlagNames
    }

    onMounted(() => {
      initFormData()
    })

    watch(() => props.user, () => {
      initFormData()
    }, { deep: true })

    return {
      userRoleText,
      isSubmitting,
      selectedFlagNames,
      removedFlaggingIds,
      formData,
      formTitle,
      formSubmitText,
      displayImage,
      isPersisted,
      isNew,
      isValid,
      userSystemFlagNames,
      canUseNewClosing,

      onSetImage,
      onSubmit,
      onDelete,
      onCancel,
      onComboBoxInput,

      icons: {
        mdiTrashCanOutline,
      },
    }
  },
}
</script>
