<!--NOTE: 時間ないのでコンポーネントに状態もたせてとりあえずつくっちゃうよ(anti pattern)-->
<template>
  <v-container>
    <h1>{{ date }} 日報</h1>

    <v-menu
      transition="scale-transition"
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          :prepend-icon="icons.mdiCalendar"
          :value="date"
          style="width: fit-content;"
          v-bind="attrs"
          v-on="on"
        >
          <template #append-outer>
            <v-btn
              color="secondary"
              :ripple="false"
              small
              @click="getToday"
            >
              本日
            </v-btn>
            <v-divider
              vertical
              inset
              class="mx-2"
            />
            <v-btn
              color="primary"
              :ripple="false"
              small
              :to="{ name: 'eod-closing', query: { date } }"
            >
              <v-icon left>
                {{ icons.mdiClockEnd }}
              </v-icon>
              クロージング
            </v-btn>
          </template>
        </v-text-field>
      </template>

      <v-date-picker
        v-model="date"
        locale="ja"
        :day-format="date => new Date(date).getDate()"
        color="primary"
        :max="today"
        no-title
      />
    </v-menu>

    <v-row>
      <v-col cols="12">
        <EodReportClubAggregations
          :key="`EodReportClubAggregations-${date}`"
          :date="date"
        />
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col cols="12">
        <h2>レポート</h2>
      </v-col>
      <v-col cols="12">
        <Reports
          :key="`Reports-${date}`"
          :date="date"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  ref,
  inject,
  onMounted,
  watch,
} from '@vue/composition-api'
import {
  mdiClockEnd,
  mdiWindowClose,
  mdiSync,
  mdiCalendar,
} from '@mdi/js'
import { useRouter } from '@core/utils'
import { parseISO, isAfter } from 'date-fns'
import TimeApi from '@/api/v2/utility/Time'
import EodReportClubAggregations from './components/EodReportClubAggregations.vue'
import Reports from './components/Reports.vue'

export default {
  components: {
    EodReportClubAggregations,
    Reports,
  },
  setup() {
    const currentClubMeta = inject('currentClubMeta', { today: null })
    const { route, router } = useRouter()
    const today = currentClubMeta.today || new Date().toISOString().slice(0, 10)
    const date = ref(route.value.query.date || today)
    // TODO route.query.date
    const isDataActive = ref(false)
    const onClosingDialog = ref(false)

    const getToday = async () => {
      const res = await TimeApi.getToday()

      if (res.data.status === 'success') {
        date.value = res.data.data
      }
    }

    watch(() => date.value, newDate => {
      const dateObj = parseISO(newDate)
      const todayObj = parseISO(today)
      if (isAfter(dateObj, todayObj)) date.value = today

      router.replace({
        name: route.value.name,
        query: {
          ...route.value.query,
          date: date.value,
        },
      })
    })

    onMounted(async () => {
      if (!date.value) await getToday()
    })

    return {
      today,
      date,
      isDataActive,
      onClosingDialog,

      getToday,

      icons: {
        mdiClockEnd,
        mdiWindowClose,
        mdiSync,
        mdiCalendar,
      },
    }
  },
}
</script>

<style lang="scss">
.aggregation-table {
  white-space: nowrap;
}
.daily-report-contents-wrapper {
  background: rgba(0,0,0,.06);
  border-radius: 10px;
  backdrop-filter: blur(10px);
}
.disable-actions {
  opacity: .2;
  pointer-events: none;
}
</style>
