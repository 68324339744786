import { computed } from '@vue/composition-api'

// NOTE: setup内でcurrentXXXが使えるように
export default () => {
  const currentUser = computed(() => {
    return JSON.parse(localStorage.getItem('currentUser') || '{}')
  })

  const currentClub = computed(() => {
    return JSON.parse(localStorage.getItem('currentClub') || '{}')
  })

  const userAbilities = computed(() => {
    return JSON.parse(localStorage.getItem('userAbilities') || '[]')
  })

  return {
    currentUser,
    currentClub,
    userAbilities,
  }
}
