<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <v-skeleton-loader
      v-if="isMounting"
      type="table"
    />
    <v-data-table
      v-else
      :loading="isLoading"
      :headers="headers"
      :items="items"
      :header-props="{ sortByText: 'ソート' }"
      :footer-props="{ itemsPerPageText: '行/ページ:' }"
      no-data-text="データがありません"
      loading-text="読込中..."
    >
      <template #top>
        <v-toolbar
          flat
          color="transparent"
        >
          <v-toolbar-title>勤怠</v-toolbar-title>
          <v-spacer />
          <v-chip-group
            v-model="targetRole"
            class="mr-5"
          >
            <v-chip
              v-for="(role, key) in roles"
              :key="key"
              filter
              outlined
              :value="key"
            >
              {{ role }}
            </v-chip>
          </v-chip-group>
          <CreateTimecardDialogButton
            :timecard-statuses="timecardStatuses"
            :users="targetUsers"
            :disabled="isLoading"
            @create="createTimecard"
          />
          <v-btn
            icon
            depressed
            :ripple="false"
            :loading="isLoading"
            class="ml-2 mr-1"
            @click="getTimecards"
          >
            <v-icon v-text="icons.mdiReload" />
          </v-btn>
        </v-toolbar>
      </template>

      <template
        v-for="header in headers"
        #[`item.${header.value}`]="{ item }"
      >
        <span
          v-if="header.value === 'userName'"
          :key="`menu-${header.value}-${item.id}`"
        >{{ item.userName }}</span>

        <v-menu
          v-else
          :key="`menu-${header.value}-${item.id}`"
          :close-on-content-click="false"
          :nudge-width="200"
          :disabled="header.value === 'userName'"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-if="header.value === 'startAt' || header.value === 'leaveAt'"
              :value="timeText(item, header.value)"
              readonly
              dense
              hide-details
              :error="isInvalidWorkingDuration(item.startAt, item.leaveAt)"
              v-bind="attrs"
              v-on="on"
            />
            <v-text-field
              v-else-if="header.value === 'timecardStatusId'"
              :value="item.meta.timecardStatusName"
              readonly
              dense
              hide-details
              v-bind="attrs"
              v-on="on"
            />
            <v-text-field
              v-else-if="header.value === 'lateMinutes' || header.value === 'earlyLeaveMinutes'"
              :value="item[header.value]"
              readonly
              dense
              hide-details
              v-bind="attrs"
              v-on="on"
            />
            <template v-else-if="header.value === 'breakTimecards'">
              <v-menu nudge-width="64">
                <template #activator="{ on: breakTimecardOn, attrs: breakTimecardAttrs }">
                  <v-col
                    tabindex="0"
                    class="cursor-pointer"
                    v-bind="breakTimecardAttrs"
                    v-on="breakTimecardOn"
                  >
                    <v-text-field
                      v-for="(breakTimecard, id) in item.breakTimecards"
                      :key="`break-timecard-menu-${id}`"
                      :value="timeText(breakTimecard, 'startAt') + ' - ' + timeText(breakTimecard, 'endAt')"
                      readonly
                      dense
                      hide-details
                      :error="isInvalidBreakingDuration(breakTimecard.startAt, breakTimecard.endAt)"
                      v-bind="breakTimecardAttrs"
                      v-on="breakTimecardOn"
                    />
                  </v-col>
                </template>

                <template #default>
                  <v-list dense>
                    <v-list-item
                      v-for="(bt, btId) in item.breakTimecards"
                      :key="`break-timecard-list-item-${btId}`"
                    >
                      <v-list-item-title
                        :class="{ ['error--text']: isInvalidBreakingDuration(bt.startAt, bt.endAt) }"
                        v-text="`${timeText(bt, 'startAt') + ' - ' + timeText(bt, 'endAt')}`"
                      />
                      <v-list-item-action>
                        <v-menu :close-on-content-click="false">
                          <template #activator="{ on: btEditOn, attrs: btEditAttrs }">
                            <v-btn
                              icon
                              v-bind="btEditAttrs"
                              v-on="btEditOn"
                            >
                              <v-icon
                                small
                                v-text="icons.mdiPencilOutline"
                              />
                            </v-btn>
                          </template>
                          <template #default>
                            <v-card>
                              <v-card-subtitle>休憩</v-card-subtitle>
                              <v-card-text>
                                <v-row>
                                  <v-col cols="12">
                                    <v-text-field
                                      v-model="bt.startAt"
                                      label="IN"
                                      type="datetime-local"
                                      dense
                                      clearable
                                      autofocus
                                      :error="isInvalidBreakingDuration(bt.startAt, bt.endAt)"
                                      @blur="debounceUpdateBreakTimecard(btId, bt, item.id)"
                                      @click="setDefaultDateTime(bt, 'startAt')"
                                      @focus="setDefaultDateTime(bt, 'startAt')"
                                    />
                                    <v-text-field
                                      v-model="bt.endAt"
                                      label="OUT"
                                      type="datetime-local"
                                      dense
                                      clearable
                                      :error="isInvalidBreakingDuration(bt.startAt, bt.endAt)"
                                      @blur="debounceUpdateBreakTimecard(btId, bt, item.id)"
                                      @click="setDefaultDateTime(bt, 'startAt')"
                                      @focus="setDefaultDateTime(bt, 'endAt')"
                                    />
                                    <p class="text--secondary text-xs d-flex pr-2">
                                      <v-spacer />
                                      休憩: {{ durationText(bt.startAt, bt.endAt).hoursAndMinutes }}
                                    </p>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </template>
                        </v-menu>
                      </v-list-item-action>
                      <v-list-item-action v-if="Object.keys(item.breakTimecards).length > 1">
                        <v-btn
                          icon
                          @click="destroyBreakTimecard(btId, item.id)"
                        >
                          <v-icon
                            small
                            v-text="icons.mdiTrashCanOutline"
                          />
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item @click="createBreakTimecard(item.id)">
                      <v-list-item-icon>
                        <v-icon
                          small
                          v-text="icons.mdiPlus"
                        />
                      </v-list-item-icon>
                      <v-list-item-title>追加</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </template>
              </v-menu>
            </template>
          </template>

          <template #default>
            <v-card>
              <template v-if="header.value === 'startAt'">
                <v-card-text>
                  <v-text-field
                    v-model="item.startAt"
                    type="datetime-local"
                    dense
                    autofocus
                    :error="isInvalidWorkingDuration(item.startAt, item.leaveAt)"
                    @blur="debounceUpdateTimecard(item, header)"
                  />
                  <p class="text--secondary text-xs d-flex pr-2">
                    <v-spacer />
                    稼働: {{ durationText(item.startAt, item.leaveAt).hoursAndMinutes }}
                  </p>
                </v-card-text>
              </template>

              <template v-else-if="header.value === 'leaveAt'">
                <v-card-text>
                  <v-text-field
                    v-model="item.leaveAt"
                    type="datetime-local"
                    dense
                    autofocus
                    clearable
                    :error="isInvalidWorkingDuration(item.startAt, item.leaveAt)"
                    @focus="setDefaultDateTime(item, 'leaveAt')"
                    @click="setDefaultDateTime(item, 'leaveAt')"
                    @blur="debounceUpdateTimecard(item, header)"
                  />
                  <p class="text--secondary text-xs d-flex pr-2">
                    <v-spacer />
                    稼働: {{ durationText(item.startAt, item.leaveAt).hoursAndMinutes }}
                  </p>
                </v-card-text>
              </template>

              <template v-else-if="header.value === 'lateMinutes' || header.value === 'earlyLeaveMinutes'">
                <v-card-text>
                  <v-text-field
                    v-model="item[header.value]"
                    type="number"
                    dense
                    autofocus
                    @change="debounceUpdateTimecard(item, header)"
                  >
                    <template #append-outer>
                      分
                    </template>
                  </v-text-field>
                </v-card-text>
              </template>

              <template v-else-if="header.value === 'timecardStatusId'">
                <v-card-text>
                  <v-select
                    v-model="item.timecardStatusId"
                    :items="timecardStatuses"
                    item-text="attributes.name"
                    item-value="attributes.id"
                    dense
                    clearable
                    autofocus
                    @change="debounceUpdateTimecard(item, header)"
                  />
                </v-card-text>
              </template>
            </v-card>
          </template>
        </v-menu>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-btn
          icon
          :ripple="false"
          @click="destroyTimecard(item.id)"
        >
          <v-icon
            small
            v-text="icons.mdiTrashCanOutline"
          />
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  ref,
  computed,
  toRefs,
  onMounted,
} from '@vue/composition-api'
import {
  mdiReload,
  mdiTrashCanOutline,
  mdiPencilOutline,
  mdiPlus,
} from '@mdi/js'
import { format, parseISO, differenceInMinutes } from 'date-fns'
import { debounce } from 'lodash'
import TimecardApi from '@/api/v2/eod-closing/Timecard'
import BreakTimecardApi from '@/api/v2/eod-closing/BreakTimecard'
import TimecardStatusApi from '@/api/v2/eod-closing/TimecardStatus'
import UserApi from '@/api/waiter/User'
import useCompInit from '@/views/composable/useCompInit'
import CreateTimecardDialogButton from './CreateTimecardDialogButton.vue'

export default {
  components: {
    CreateTimecardDialogButton,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { date } = toRefs(props)
    const timecards = ref([])
    const timecardStatuses = ref([])
    const users = ref([])
    const isMounting = ref(true)
    const targetRole = ref('cast')
    const roles = { cast: 'キャスト', waiter: 'スタッフ', alliance: 'アライアンス' }
    const headers = [
      ['userName', 'ユーザー', { width: '160px' }],
      ['startAt', '開始'],
      ['leaveAt', '終了'],
      ['breakTimecards', '休憩', { width: '200px' }],
      ['lateMinutes', '遅刻/分', { width: '80px' }],
      ['earlyLeaveMinutes', '早退/分', { width: '80px' }],
      ['timecardStatusId', 'ステータス'],
      ['actions', ''],
    ].map(([value, text, options = {}]) => {
      return {
        text,
        value,
        ...options,
      }
    })

    const { initWith, isLoading } = useCompInit()

    const targetTimecards = computed(() => timecards.value.filter(timecard => timecard.meta.userRole === targetRole.value))
    const targetUsers = computed(() => users.value.filter(user => user.attributes.role === targetRole.value))
    const items = computed(() => {
      return targetTimecards.value.map(timecard => {
        const breakTimecards = {}
        timecard.attributes.breakTimecards.data.forEach(breakTimecard => {
          const { startAt, endAt } = breakTimecard.attributes
          breakTimecards[breakTimecard.id] = { startAt, endAt }
        })

        const meta = {
          userRole: roles[timecard.meta.userRole],
          _originalValues: {
            startAt: timecard.attributes.startAt,
            leaveAt: timecard.attributes.leaveAt,
            lateMinutes: timecard.attributes.lateMinutes,
            earlyLeaveMinutes: timecard.attributes.earlyLeaveMinutes,
            timecardStatusId: timecard.attributes.timecardStatusId,
            breakTimecards: JSON.parse(JSON.stringify(breakTimecards)),
          },
          timecardStatusName: timecard.meta.timecardStatusName,
          get originalValues() {
            return { ...this._originalValues } // 初期値をコピーして返す
          },
        }

        return {
          id: timecard.attributes.id,
          userName: timecard.meta.userName,
          startAt: timecard.attributes.startAt,
          leaveAt: timecard.attributes.leaveAt,
          lateMinutes: timecard.attributes.lateMinutes,
          earlyLeaveMinutes: timecard.attributes.earlyLeaveMinutes,
          timecardStatusId: timecard.attributes.timecardStatusId,
          breakTimecards,
          meta,
        }
      })
    })

    const timeText = computed(() => (resource, key) => {
      const dateTimeStr = resource[key]

      return dateTimeStr ? dateTimeStr.split('T')[1] : ''
    })

    const isEndBeforeStart = (start, end) => {
      const startDate = parseISO(start)
      const endDate = parseISO(end)
      if ([startDate, endDate].some(incomingDate => Number.isNaN(incomingDate.getTime()))) return true

      return endDate < startDate
    }

    const isInvalidWorkingDuration = computed(() => (startAt, leaveAt) => {
      if (!startAt && leaveAt) return true // 開始がない=>NG
      if (startAt && !leaveAt) return false// 終了だけない=>OK
      if (!startAt && !leaveAt) return true // 両方ない=>NG

      return isEndBeforeStart(startAt, leaveAt)
    })

    const isInvalidBreakingDuration = computed(() => (startAt, endAt) => {
      if (!startAt && endAt) return true // 開始だけない=>NG
      if (startAt && !endAt) return false // 終了だけない=>OK
      if (!startAt && !endAt) return false // 両方ない=>OK

      return isEndBeforeStart(startAt, endAt)
    })

    const durationText = computed(() => (startAt, endAt) => {
      let startDate = parseISO(startAt)
      let endDate = parseISO(endAt)

      if (isEndBeforeStart(startAt, endAt)) {
        const now = new Date()
        startDate = now
        endDate = now
      }

      // 滞在時間を計算（分単位）
      const minutes = differenceInMinutes(endDate, startDate)

      // 滞在時間を計算（時間と分の形式に変換）
      const hours = Math.floor(minutes / 60)
      const remainingMinutes = minutes % 60
      const hoursAndMinutes = `${String(hours).padStart(2, ' ')}時間${String(remainingMinutes).padStart(2, ' ')}分`

      return {
        totalMinutes: minutes,
        hoursAndMinutes,
      }
    })

    const setDefaultDateTime = (resource, key) => {
      // eslint-disable-next-line no-param-reassign
      if (resource[key] === null) resource[key] = format(new Date(), "yyyy-MM-dd'T'HH:mm")
    }

    // タイムカード更新
    const updateTimecard = async (item, header) => {
      try {
        const params = { [header.value]: item[header.value] }
        const res = await TimecardApi.updateTimecard(item.id, params)
        if (res.data.status === 'success') {
          const { data } = res.data.data
          const idx = timecards.value.findIndex(t => t.attributes.id === data.attributes.id)
          if (idx >= 0) {
            timecards.value.splice(idx, 1, data)
          } else {
            timecards.value.push(data)
          }
        }
      } catch (error) {
        // TODO
      }
    }
    const debounceUpdateTimecard = debounce((item, header) => {
      if (item.meta.originalValues[header.value] === item[header.value]) return

      if (header.value === 'startAt' || header.value === 'leaveAt') {
        if (isInvalidWorkingDuration.value(item.startAt, item.leaveAt)) return
      }

      updateTimecard(item, header)
    }, 500)
    // /タイムカード更新

    // タイムカード作成
    const createTimecard = async params => {
      try {
        isLoading.value = true
        const res = await TimecardApi.createTimecard(params)
        if (res.data.status === 'success') {
          const { data } = res.data.data
          timecards.value.push(data)
        }
      } catch (error) {
        // TODO
      } finally {
        isLoading.value = false
      }
    }
    // /タイムカード作成

    // タイムカード削除
    const destroyTimecard = async id => {
      try {
        isLoading.value = true
        const res = await TimecardApi.destroyTimecard(id)
        if (res.data.status === 'success') {
          const idx = timecards.value.findIndex(t => t.attributes.id === id)
          timecards.value.splice(idx, 1)
        }
      } catch (error) {
        // TODO
      } finally {
        isLoading.value = false
      }
    }
    // /タイムカード削除

    // 休憩更新
    const upsertBreakTimecard = (parentTimecardId, breakTimecardData) => {
      const timecardIdx = timecards.value.findIndex(t => t.attributes.id === parentTimecardId)
      const updatedTimecard = { ...timecards.value[timecardIdx] }
      const idx = updatedTimecard.attributes.breakTimecards.data.findIndex(b => b.attributes.id === breakTimecardData.attributes.id)
      if (idx >= 0) {
        updatedTimecard.attributes.breakTimecards.data.splice(idx, 1, breakTimecardData)
      } else {
        updatedTimecard.attributes.breakTimecards.data.push(breakTimecardData)
      }
      timecards.value.splice(timecardIdx, 1, updatedTimecard)
    }
    const updateBreakTimecard = async (id, breakTimecard, parentTimecardId) => {
      try {
        const { startAt, endAt } = breakTimecard
        const breakTimecardParams = {
          id,
          breakTimeStartAt: startAt,
          breakTimeEndAt: endAt,
          timecardId: parentTimecardId,
        }
        const res = await BreakTimecardApi.updateBreakTimecard(breakTimecardParams)
        if (res.data.status === 'success') {
          const { data } = res.data.data
          upsertBreakTimecard(parentTimecardId, data)
        }
      } catch (error) {
        // TODO
      }
    }
    const debounceUpdateBreakTimecard = debounce((id, breakTimecard, parentTimecardId) => {
      const item = items.value.find(i => i.id === parentTimecardId)

      if (item) {
        const originalBreakTimecard = item.meta.originalValues.breakTimecards[id]
        if (originalBreakTimecard.startAt === breakTimecard.startAt && originalBreakTimecard.endAt === breakTimecard.endAt) return
        if (isInvalidBreakingDuration.value(breakTimecard.startAt, breakTimecard.endAt)) return

        updateBreakTimecard(id, breakTimecard, parentTimecardId)
      }
    }, 500)
    // /休憩更新

    // 休憩作成
    const createBreakTimecard = async parentTimecardId => {
      try {
        const res = await BreakTimecardApi.createBreakTimecard({ timecardId: parentTimecardId })
        if (res.data.status === 'success') {
          const { data } = res.data.data
          upsertBreakTimecard(parentTimecardId, data)
        }
      } catch (error) {
        // TODO
      }
    }
    // /休憩作成

    // 休憩削除
    const destroyBreakTimecard = async (id, parentTimecardId) => {
      try {
        const res = await BreakTimecardApi.destroyBreakTimecard(id, parentTimecardId)
        if (res.data.status === 'success') {
          const timecardIdx = timecards.value.findIndex(t => t.attributes.id === parentTimecardId)
          if (timecardIdx < 0) return
          const updatedTimecard = { ...timecards.value[timecardIdx] }
          const idx = updatedTimecard.attributes.breakTimecards.data.findIndex(b => b.attributes.id === Number(id))
          if (idx >= 0) {
            updatedTimecard.attributes.breakTimecards.data.splice(idx, 1)
            timecards.value.splice(timecardIdx, 1, updatedTimecard)
          }
        }
      } catch (error) {
        // TODO
      }
    }
    // /休憩削除

    const getTimecards = async () => {
      isLoading.value = true
      const res = await TimecardApi.getTimecards(date.value)
      if (res.data.status === 'success') timecards.value = res.data.data.data
      isLoading.value = false
    }

    const getTimecardStatuses = async () => {
      isLoading.value = true
      const res = await TimecardStatusApi.getTimecardStatus(date.value)
      if (res.data.status === 'success') timecardStatuses.value = res.data.data.data
      isLoading.value = false
    }

    const getUsers = async () => {
      const res = await UserApi.getUsers()
      if (res?.data) {
        users.value = [...res.data.users.data]
      }
    }

    onMounted(async () => {
      await initWith([
        getTimecards(),
        getTimecardStatuses(),
        getUsers(),
      ])
      isMounting.value = false
    })

    return {
      isLoading,
      isMounting,
      headers,
      items,
      timeText,
      durationText,
      isInvalidWorkingDuration,
      isInvalidBreakingDuration,
      timecards,
      timecardStatuses,
      users,
      roles,
      targetRole,
      targetUsers,

      getTimecards,
      createTimecard,
      setDefaultDateTime,
      destroyTimecard,
      debounceUpdateTimecard,
      debounceUpdateBreakTimecard,
      createBreakTimecard,
      destroyBreakTimecard,

      icons: {
        mdiReload,
        mdiTrashCanOutline,
        mdiPencilOutline,
        mdiPlus,
      },
    }
  },
}
</script>
